import React, { useCallback, useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';
import DrawerSearchButton from '../components/DrawerSearchButton';
import SearchDrawer from '../components/SearchDrawer';
import { NavigationContext, TOGGLE_MOBILE_DRAWER } from '../../context/NavigationContext';
import SearchContent from './SearchContent';
import {
    MenuIcon,
    MenuIconButton,
} from './styledComponents';
import {
    compileStyledComponents,
    extractSearchWord,
    getSelectedTypes,
} from './utils';
import useClickOutside from '../../util/useClickOutside';

const SearchBox = ({
    attributes: {
        fontSelection,
        initialSearchType,
        inMobileDrawer,
        mobileButtonLabel,
        preIcon,
        postIcon,
        searchDocumentTypes,
    } = {},
    structureType,
    styles = {},
    styles: { drawerSearchButton } = {},
}) => {
    const [isSearchExpanded, setIsSearchExpanded] = useState(false);
    const [isTypesExpanded, setIsTypesExpanded] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [selectedType, setSelectedType] = useState(initialSearchType);
    const searchFieldRef = useRef();
    const searchFieldContainer = useRef();

    const {
        state: { menuOpen },
        dispatch,
        onSearch,
        onSearchRedirect,
    } = useContext(NavigationContext);

    const searchTypes = getSelectedTypes(searchDocumentTypes);
    const isMobile = structureType === 'mobile';

    const { searchBoxStyles, StyledComponent } = compileStyledComponents(styles);

    const onSearchSlow = useCallback(throttle(onSearch, 1000, {
        leading: false,
        trailing: true,
    }), []);

    const handleOnchangeType = e => {
        e.preventDefault();
        e.stopPropagation();
        setIsTypesExpanded(false);
        setSelectedType(e.currentTarget.value);
        setSearchValue('');
    };

    const handleClickOutside = () => {
        setIsSearchExpanded(false);
    };

    useClickOutside(searchFieldContainer, handleClickOutside);

    const handleOnKeydown = e => {
        const searchWord = extractSearchWord(e.target.value);

        if (e.key === 'Enter' && searchWord.length > 2) {
            e.preventDefault();

            if (selectedType) {
                setIsSearchExpanded(false);
                if (menuOpen) {
                    dispatch({ type: TOGGLE_MOBILE_DRAWER });
                }
                if (onSearchRedirect) {
                    onSearchRedirect({ type: selectedType, value: searchWord });
                }
            }
        }
    };

    const handleOnSearchChange = ({ target: { value = '' } = {} }) => {
        const searchWord = extractSearchWord(value);
        setSearchValue(searchWord);
        setIsTypesExpanded(false);

        if (searchWord.length > 2 && selectedType) {
            onSearchSlow({
                type: selectedType,
                value,
            });
        }
    };

    const handleSearchResultClick = () => {
        setIsSearchExpanded(false);
        if (menuOpen) {
            dispatch({ type: TOGGLE_MOBILE_DRAWER });
        }
    };

    const handleSearchToggle = () => {
        setIsSearchExpanded(state => !state);
        setIsTypesExpanded(false);
    };

    const handleSearchTypesToggle = () => {
        setIsTypesExpanded(state => !state);
        setSearchValue('');
    };

    const searchContentProps = {
        handleOnchangeType,
        handleOnKeydown,
        handleOnSearchChange,
        handleSearchResultClick,
        handleSearchToggle,
        handleSearchTypesToggle,
        inMobileDrawer,
        isMobile,
        isSearchExpanded,
        isTypesExpanded,
        searchBoxStyles,
        searchFieldRef,
        searchValue,
        selectedType,
        searchTypes,
    };

    return (
        <>
            {inMobileDrawer ? (
                <>
                    <DrawerSearchButton
                        onClick={handleSearchToggle}
                        styles={drawerSearchButton}
                        attributes={{ label: mobileButtonLabel, preIcon, postIcon, fontSelection }}
                    />
                    <SearchDrawer>
                        <StyledComponent inMobileDrawer={inMobileDrawer}>
                            <SearchContent {...searchContentProps} />
                        </StyledComponent>
                    </SearchDrawer>
                </>
            ) : (
                <StyledComponent inMobileDrawer={inMobileDrawer} ref={searchFieldContainer}>
                    <SearchContent {...searchContentProps} />
                    <MenuIconButton
                        onClick={handleSearchToggle}
                        isSearchExpanded={isSearchExpanded}
                    >
                        <MenuIcon icon="Search" />
                    </MenuIconButton>
                </StyledComponent>
            )}
        </>
    );
};

SearchBox.propTypes = {
    attributes: PropTypes.object,
    structureType: PropTypes.string,
    styles: PropTypes.object,
};

export default SearchBox;
