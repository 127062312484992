import { createContext } from 'react';

export const TOGGLE_ACCORDION = 'toggle-accordion';
export const TOGGLE_MOBILE_DRAWER = 'toggle-mobile-drawer';
export const WINDOW_RESIZE = 'window-resize';
export const NavigationContext = createContext();

export default {
    TOGGLE_ACCORDION,
    TOGGLE_MOBILE_DRAWER,
    WINDOW_RESIZE,
    NavigationContext,
};
