const downloadsLibraryId = 2732;
const PornhubPremiumAddonId = 5411;

export const USER_ADDONS = 'USER_ADDONS';
export const USER_LIBRARY = 'USER_LIBRARY';
export const CAMPAIGN_BUNDLES = 'CAMPAIGN_BUNDLES';

export const BUNDLES_KEYS = {
    [USER_ADDONS]: {
        linkQueryKey: 'addon',
        linkType: 'EXPLORE_SCENES',
    },
    [CAMPAIGN_BUNDLES]: {
        linkType: 'PRODUCT_PAGE',
    },
    [USER_LIBRARY]: {
        linkQueryKey: 'purchasableAddon',
        linkType: 'EXPLORE_SCENES',
    },
};

const sortByConfig = (collection, sortBy) => {
    let sortFunc = () => null;

    if (sortBy === 'id') {
        sortFunc = (a, b) => a.id - b.id;
    }
    if (sortBy === 'title') {
        sortFunc = (a, b) => a.title && a.title.localeCompare(b.title);
    }

    return collection.sort(sortFunc);
};

export const updateContentValues = ({
    bundelsSort,
    content = [],
    dataType,
    linkQueryKey,
    linkType,
    user = {},
}) => {
    let collection = [...content.collection];

    if (dataType === USER_LIBRARY && user.isNSF) {
        return [];
    }

    // PornhubPremium hardcoded value
    if (linkQueryKey === 'addon') {
        collection = collection.map(item => {
            if (item.id === PornhubPremiumAddonId) {
                return {
                    ...item,
                    linkType: 'MY_LIBRARY',
                };
            }

            return item;
        });
    }

    // The Downloads removing from purchased list
    if (linkQueryKey === 'addon') {
        collection = collection.filter(item => item.id !== downloadsLibraryId);
    }

    // sort by config value
    collection = sortByConfig(collection, bundelsSort);

    return {
        collection,
        linkType,
        linkQueryKey,
    };
};

export const filterByTags = (bundles, marketplaceTags = '') => {
    if (!marketplaceTags) {
        return bundles;
    }

    const tagsArr = marketplaceTags.split(';').map(Number);

    return bundles.filter(bundle => {
        if (!Array.isArray(bundle.tags)) {
            console.error('Error: the bundle does not have tags data');
            return true;
        }

        const itemTags = bundle.tags.map(tag => tag.id);
        return itemTags.some(item => tagsArr.includes(item));
    });
};

export const updateBundlesValues = (bundles = []) => bundles.map(bundle => {
    const tmpBundle = { ...bundle };

    tmpBundle.id = bundle.bundleId;

    if (bundle.bundleOperation === 'purchase') {
        tmpBundle.linkType = 'MY_LIBRARY';
    }

    return tmpBundle;
});
