import React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import App from './core/App';
import * as serviceWorker from './service/serviceWorker';
import { isDev, propsFiles } from './core/config';

window.renderNavigation = (containerId, history, otherProps) => {
    const container = document.getElementById(containerId);
    if (container) {
        render(<App history={history} otherProps={{ ...otherProps }} />, container);
        serviceWorker.unregister();
    }
};

window.unmountNavigation = containerId => {
    const container = document.getElementById(containerId);
    if (container) {
        unmountComponentAtNode(container);
    }
};

if (typeof window.mfe === 'undefined') {
    window.mfe = {};
}
if (typeof window.mfe.nav === 'undefined') {
    window.mfe.nav = {};
}
window.mfe.nav.git = GIT; // eslint-disable-line no-undef
window.mfe.nav.version = VERSION; // eslint-disable-line no-undef

if (isDev && document.getElementById('localhost-container')) {
    let mockProps = {};
    try {
        // eslint-disable-next-line
        const { default: mocks } = require(`./mocks/${propsFiles}`);
        mockProps = mocks;
    } catch (err) {
        console.error(err);
    }

    render(
        <React.StrictMode>
            <App otherProps={mockProps} />
        </React.StrictMode>,
        document.getElementById('localhost-container'),
    );
    serviceWorker.unregister();
}
