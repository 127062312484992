import { getLuminance, darken, lighten } from 'polished';
import { curry, get } from 'lodash';

export const themeGet = curry((theme, ...keys) => get(theme, ...keys));
export const colors = curry((color, props) => get(props.theme.color, color));
export const assets = curry((asset, props) => get(props.theme.assets, asset));

export function readableColor(color, darkColor = '#000', lightColor = '#fff') {
    try {
        const luminance = getLuminance(color);
        return luminance > 0.225 ? darkColor : lightColor;
    } catch (e) {
        return darkColor;
    }
}

export function isDark(color) {
    return getLuminance(color) < 0.225;
}

export function softenColor(color, props) {
    const bgColor = colors(color, props);
    return isDark(bgColor) ? lighten(0.05, bgColor) : darken(0.05, bgColor);
}
