import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { NavigationContext } from '../../context/NavigationContext';
import getRouteFromType from '../../util/getRouteFromReleaseType';
import {
    ResultContainer,
    ResultLink,
    SearchResults,
} from './styledComponents';

const SearchResult = ({
    handleSearchResultClick,
    isMobile,
    isShow,
}) => {
    const { result, showFirstNamesOnly } = useContext(NavigationContext);
    const { actors = [], releases = [] } = result;
    const isResult = actors || releases;

    return !isShow ? null : (
        <SearchResults isActive={isResult} isMobile={isMobile}>
            {releases && releases.map(release => (
                <ResultContainer key={release.id}>
                    <ResultLink
                        title={release.title}
                        onClick={handleSearchResultClick}
                        to={{
                            type: getRouteFromType(release.type),
                            payload: { release },
                        }}
                    >
                        {release.title}
                    </ResultLink>
                </ResultContainer>
            ))}
            {actors && actors.map(actor => (
                <ResultContainer key={actor.id}>
                    <ResultLink
                        title={actor.getName(showFirstNamesOnly)}
                        onClick={handleSearchResultClick}
                        to={{
                            type: 'ACTOR_PROFILE',
                            payload: { actor },
                        }}
                    >
                        {actor.getName(showFirstNamesOnly)}
                    </ResultLink>
                </ResultContainer>
            ))}
        </SearchResults>
    );
};

SearchResult.propTypes = {
    handleSearchResultClick: PropTypes.func,
    isMobile: PropTypes.bool,
    isShow: PropTypes.bool,
};

export default SearchResult;
