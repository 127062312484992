import React from 'react';
import PropTypes from 'prop-types';

import NavComponent from './components/NavComponent';
import WidthWrapper from './components/WidthWrapper';

const Wrapper = ({
    attributes: {
        isSticky = false,
        isContentPushed = true,
        isContentFullWidth = false,
        toggleOnScroll = false,
        widthOverrides = {},
    } = {},
    styles = {},
    isPreview,
    structureType,
    children,
}) => (
    <NavComponent
        styles={styles}
        isSticky={isSticky && !isPreview}
        isContentPushed={isContentPushed}
        isContentFullWidth={isContentFullWidth}
        toggleOnScroll={toggleOnScroll}
        structureType={structureType}
    >
        <WidthWrapper
            isSticky={isSticky && !isPreview}
            isContentFullWidth={isContentFullWidth}
            widthOverrides={widthOverrides}
        >
            {children}
        </WidthWrapper>
    </NavComponent>

);

Wrapper.propTypes = {
    attributes: PropTypes.shape({
        isSticky: PropTypes.bool,
        toggleOnScroll: PropTypes.bool,
        isContentPushed: PropTypes.bool.isRequired,
        isContentFullWidth: PropTypes.bool.isRequired,
        widthOverrides: PropTypes.shape({
            small: PropTypes.string,
            medium: PropTypes.string,
            large: PropTypes.string,
            extraLarge: PropTypes.string,
            huge: PropTypes.string,
        }),
    }),
    children: PropTypes.any,
    isPreview: PropTypes.bool,
    structureType: PropTypes.string.isRequired,
    styles: PropTypes.object,
};

export default Wrapper;
