import { css } from 'styled-components';
import { mediaBetween, mediaOnly } from './media';
import { breakpointBetween } from './breakpoints';

function calculateContainer(size, span, gutter, width) {
    const parsedGutter = parseInt(gutter, 10);
    return (size / span) * (width - (span - 1) * parsedGutter) + (size - 1) * parsedGutter;
}

export function optimizeBreakpoints(inputs) {
    const arrayedBreakpoints = Object.entries(inputs);
    const result = [];

    for (let i = 0, j = 0; i < arrayedBreakpoints.length; i++) {
        if (arrayedBreakpoints[i][1]) {
            while (arrayedBreakpoints[i] && arrayedBreakpoints[i][1]) {
                if (!result[j]) {
                    result[j] = [arrayedBreakpoints[i][0]];
                } else {
                    result[j][1] = arrayedBreakpoints[i][0];
                }
                i++;
            }
            j++;
        }
    }

    return result;
}

function renderMedia(optimizedBreakpoints, breakpoints) {
    return (...args) => css`
        ${optimizedBreakpoints.map(validQueries => {
            if (validQueries.length > 1) {
                return mediaBetween(validQueries[0], validQueries[1], breakpoints)`
                        ${css(...args)}
                    `;
            }
            return mediaOnly(validQueries[0], breakpoints)`
                ${css(...args)}
            `;
        })}
    `;
}

function renderPercentageMediaMax(optimizedBreakpoints, breakpoints) {
    const getMaxPixelValues = Object.entries(optimizedBreakpoints);

    return (...args) => css`
        ${getMaxPixelValues.map(
            ([breakpoint, pixels]) =>
                breakpointBetween(breakpoints[breakpoint], pixels)`
                ${css(...args)}
            `,
        )}
    `;
}

function renderPercentageMediaMin(optimizedBreakpoints, breakpoints) {
    const getMinPixelValues = Object.entries(optimizedBreakpoints);
    const getBreakpoints = Object.entries(breakpoints);

    return (...args) => css`
        ${getMinPixelValues.map(([, pixels], index) => {
            const nextBreakpoint = getBreakpoints[index + 1] || [Infinity, Infinity];
            return breakpointBetween(pixels, nextBreakpoint[1])`
                ${css(...args)}
            `;
        })}
    `;
}

function renderPercentageMediaBetween(lowVals, highVals) {
    const getMinPixelValues = Object.entries(lowVals);
    const getHighPixelValues = Object.entries(highVals);

    return (...args) => css`
        ${getMinPixelValues.map(([, pixels], index) => {
            const nextBreakpoint = getHighPixelValues[index][1] || [Infinity, Infinity];
            return breakpointBetween(pixels, nextBreakpoint)`
                ${css(...args)}
            `;
        })}
    `;
}

export const selfMediaDown = width => (...args) => props => {
    const { calculatedWidths, theme } = props;
    const { breakpoints, gutter, size } = theme.grid;

    // handle pixel based layouts
    const pixelBreakpoints = Object.entries(calculatedWidths)
        .filter(x => typeof x[1] === 'number')
        .reduce((acc, [breakpoint, dims]) => {
            const isLessThan = dims <= width;
            return {
                ...acc,
                [breakpoint]: isLessThan,
            };
        }, {});

    // Handle percentages
    const percentageBreakpoints = Object.entries(calculatedWidths)
        .filter(x => Array.isArray(x[1]))
        .reduce((acc, [breakpoint, dims]) => {
            const container = dims.reduceRight(
                (accumulator, span) => calculateContainer(size, span, gutter, accumulator),
                width,
            );
            return {
                ...acc,
                [breakpoint]: container,
            };
        }, {});

    const optimizedBreakpoints = optimizeBreakpoints(pixelBreakpoints);

    // TODO optimize to merge breakpoints
    return css`
        ${renderPercentageMediaMax(percentageBreakpoints, breakpoints)`
            ${css(...args)}
        `}
        ${renderMedia(optimizedBreakpoints, breakpoints)`
            ${css(...args)}
        `}
    `;
};

export const selfMediaUp = width => (...args) => props => {
    const { calculatedWidths, theme } = props;
    const { breakpoints, gutter, size } = theme.grid;

    const pixelBreakpoints = Object.entries(calculatedWidths)
        .filter(x => typeof x[1] === 'number')
        .reduce((acc, [breakpoint, dims]) => {
            const isGreaterThan = dims >= width;
            return {
                ...acc,
                [breakpoint]: isGreaterThan,
            };
        }, {});

    const percentageBreakpoints = Object.entries(calculatedWidths)
        .filter(x => Array.isArray(x[1]))
        .reduce((acc, [breakpoint, dims]) => {
            const container = dims.reduceRight(
                (accumulator, span) => calculateContainer(size, span, gutter, accumulator),
                width,
            );
            return {
                ...acc,
                [breakpoint]: container,
            };
        }, {});

    const optimizedBreakpoints = optimizeBreakpoints(pixelBreakpoints);

    return css`
        ${renderPercentageMediaMin(percentageBreakpoints, breakpoints)`
            ${css(...args)}
        `}
        ${renderMedia(optimizedBreakpoints, breakpoints)`
            ${css(...args)}
        `}
    `;
};

export const selfMediaBetween = (width1, width2) => (...args) => props => {
    const { calculatedWidths, theme } = props;
    const { breakpoints, gutter, size } = theme.grid;

    const validBreakpoints = Object.entries(calculatedWidths)
        .filter(x => typeof x[1] === 'number')
        .reduce((acc, [breakpoint, width]) => {
            const isBetween = width >= width1 && width <= width2;
            return {
                ...acc,
                [breakpoint]: isBetween,
            };
        }, {});

    const percentageBreakpoints1 = Object.entries(calculatedWidths)
        .filter(x => Array.isArray(x[1]))
        .reduce((acc, [breakpoint, dims]) => {
            const container = dims.reduceRight(
                (accumulator, span) => calculateContainer(size, span, gutter, accumulator),
                width1,
            );
            return {
                ...acc,
                [breakpoint]: container,
            };
        }, {});

    const percentageBreakpoints2 = Object.entries(calculatedWidths)
        .filter(x => Array.isArray(x[1]))
        .reduce((acc, [breakpoint, dims]) => {
            const container = dims.reduceRight(
                (accumulator, span) => calculateContainer(size, span, gutter, accumulator),
                width2,
            );
            return {
                ...acc,
                [breakpoint]: container,
            };
        }, {});

    const optimizedBreakpoints = optimizeBreakpoints(validBreakpoints);

    return css`
        ${renderPercentageMediaBetween(percentageBreakpoints1, percentageBreakpoints2)`
            ${css(...args)}
        `}

        ${renderMedia(optimizedBreakpoints, breakpoints)`
            ${css(...args)}
        `}
    `;
};
