import { getSlug } from '../../util/routeUtils';

const getSearchResultLink = ({ searchType, itemType, item, source }) => {
    if (searchType === 'all') {
        if (itemType === 'scenes') {
            return {
                type: 'PLAYER',
                payload: {
                    release: {
                        ...item,
                        slug: getSlug(item),
                    },
                },
            };
        }

        if (itemType === 'actors') {
            return {
                type: 'ACTOR_PROFILE',
                payload: {
                    actor: {
                        ...item,
                        slug: getSlug(item),
                    },
                },
            };
        }
    }

    if (searchType === 'categories') {
        if (itemType === 'scenes') {
            return {
                type: 'SEARCH_VIDEOS',
                query: {
                    tags: item.name,
                    q: item.name,
                    source,
                },
            };
        }

        if (itemType === 'actors') {
            return {
                type: 'SEARCH_MODELS',
                query: {
                    tags: item.name,
                    q: item.name,
                    source,
                },
            };
        }
    }

    if (itemType === 'scenes') {
        return {
            type: 'PLAYER',
            payload: {
                release: {
                    ...item,
                    slug: getSlug(item),
                },
            },
        };
    }

    if (itemType === 'actors') {
        return {
            type: 'ACTOR_PROFILE',
            payload: {
                actor: {
                    ...item,
                    slug: getSlug(item),
                },
            },
        };
    }

    if (itemType === 'suggestions') {
        return {
            type: 'SEARCH_VIDEOS',
            query: {
                q: item.name,
                source,
            },
        };
    }

    // eslint-disable-next-line no-console
    console.log('Search type is out from TO options: ', { searchType, itemType });
    return {};
};

export default getSearchResultLink;
