import React, { useContext, useMemo, memo } from 'react';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import {
    NavigationContext,
} from '../context/NavigationContext';
import FeatherIcon from '../util/FeatherIcon';
import { getPathToRoute } from '../util/getPathToRoute';
import getStyles from '../util/getStyles';
import { getButtonLabel } from './components/ButtonLabel';

const defaultStyles = {
    button: {
        lineHeight: 1,
        display: 'flex',
        textDecoration: 'none',

        borderWidth: 0,
        borderStyle: 'solid',
        borderColor: 'transparent',
    },
    preIcon: { height: '1em', color: '#dddddd' },
    postIcon: { height: '1em', color: '#dddddd' },
    dropdown: { borderStyle: 'solid', borderColor: 'transparent', borderWidth: 0 },
    label: { display: 'flex', alignItems: 'center', textDecoration: 'none', padding: '4px 8px' },
};

const LoyaltyComponent = styled.div`
    display: flex;
    cursor: pointer;

    &:hover {
        & > a svg,
        & > span svg {
            transform: rotate(${props => props.postIconActiveRotation});
        }
    }

    &.collapsible.open {
        & > span svg {
            transform: rotate(${props => props.postIconActiveRotation});
        }
    }

    a, span {
        display: flex;
        flex: 1;
        align-items: center;
    }

    p {
    margin: 0;
    padding: 0 5px;
    }

    ${({ styles }) => styles}
`;

const PostIcon = styled(FeatherIcon)(({ styles }) => styles);
const PreIcon = styled(FeatherIcon)(({ styles }) => styles);

const Loyalty = ({
    id,
    children,
    styles = {},
    attributes: {
        label,
        preIcon,
        postIcon,
        postIconActiveRotation,
        fontSelection = '',
    },
    structureType,
    isChildButton,
    ...props
}) => {
    const {
        routesMap,
        state: { activeAccordionId },
        user: { isLoyaltyActive, loyalty: { pointBalance } = {} } = {},
        paths: { loyalty } = {},
    } = useContext(NavigationContext);
    const hasChildren = typeof children === 'object' && !isEmpty(children);
    const isMobileCollapsible = hasChildren && structureType === 'mobile';
    const isOpen = activeAccordionId === id || String(activeAccordionId).startsWith(id);
    const loyaltyPointsUrl = getPathToRoute({
        routesMap,
        path: loyalty || '',
    });

    const { rootStyles = {}, rootHoverStyles = {}, childrenStyles = {} } = useMemo(() => getStyles(styles));

    const Label = getButtonLabel(isMobileCollapsible, {
        ...defaultStyles.label,
        ...childrenStyles.label,
    });

    const postIconStyles = { ...defaultStyles.postIcon, ...childrenStyles.postIcon };
    const preIconStyles = { ...defaultStyles.preIcon, ...childrenStyles.preIcon };

    const buttonStyles = {
        ...defaultStyles.button,
        ...rootStyles,
        ...(structureType === 'mobile' && { flexShrink: 0 }),
        '&:hover >': {
            ...rootStyles['&:hover'],
            [Label]: {
                ...rootHoverStyles.label,
                [PostIcon]: rootHoverStyles.postIcon,
                [PreIcon]: rootHoverStyles.preIcon,
            },
        },
    };

    return (
        <>
            {isLoyaltyActive
                && (
                    <LoyaltyComponent
                        id={`btn_${id}`}
                        key={`btn_${id}`}
                        postIconActiveRotation={postIconActiveRotation}
                        {...props}
                        className={`${fontSelection} ${isMobileCollapsible ? 'collapsible' : ''} ${
                            isOpen ? 'open' : ''
                        }`}
                        styles={buttonStyles}
                    >
                        <Label
                            to={loyaltyPointsUrl}
                        >
                            <PreIcon icon={preIcon} styles={preIconStyles} />
                            {label}
                            :
                            <p>{pointBalance}</p>
                            <PostIcon icon={postIcon} styles={postIconStyles} />
                        </Label>
                    </LoyaltyComponent>
                )}
        </>
    );
};

Loyalty.propTypes = {
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    styles: PropTypes.object,
    children: PropTypes.node,
    attributes: PropTypes.object,
    structureType: PropTypes.string,
    isChildButton: PropTypes.bool,
};

export default memo(Loyalty);
