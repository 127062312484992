// shorthand from state.location.routesMap
const mockRoutes = {
    ABOUT: '/about',
    ACCOUNT: '/myaccount',
    ACTOR_PROFILE: '/actor/{{actor.id}}/{{actor.slug}}',
    BTS_PROFILE: '/bts/{{release.id}}/{{release.slug}}',
    EXPLORE_ACTORS: '/actors',
    EXPLORE_BTS: '/bts',
    EXPLORE_CATEGORIES: '/categories',
    EXPLORE_GALLERIES: '/galleries',
    EXPLORE_MOVIES: '/movies',
    EXPLORE_SCENES: '/scenes',
    EXPLORE_SERIES: '/series',
    EXPLORE_SITES: '/sites',
    EXPLORE_UNLOCKS: '/myunlocks',
    GALLERY: '/gallery/{{release.id}}/{{release.slug}}',
    HOME: '/',
    JOIN: '/join',
    LOGIN: '/login',
    LOGOUT: '/logout',
    MY_LIBRARY: '/mylibrary',
    MOVIE_PROFILE: '/movie/{{release.id}}/{{release.slug}}',
    PLAYER: '/player/{{release.id}}/{{release.slug}}',
    PRESS: '/press',
    SEARCH: '/search',
    SERIES_PROFILE: '/series/{{release.id}}/{{release.slug}}',
    SITEMAP: '/sitemap',
    STOREFRONT: '/store',
    TRAILER: '/trailer/{{release.id}}/{{release.slug}}',
    UPCOMING: '/upcoming',
};

export default mockRoutes;
