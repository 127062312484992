import queryString from 'query-string';
import kebabCase from 'lodash/kebabCase';

const PIERCING_VALUES = ['ad_id'];

export const queryStringToObject = str => queryString.parse(str);

export const objectToQueryString = queryObj => {
    const qsParams = queryString.stringify(queryObj);
    return qsParams.length > 0 ? '?'.concat(qsParams) : '';
};

export const getPiercingQuery = queryObj => Object.keys(queryObj)
    .filter(key => PIERCING_VALUES.includes(key))
    .reduce((obj, key) => ({
        ...obj,
        [key]: queryObj[key],
    }), {});

export const getSlug = (data = {}) => kebabCase(data.name || data.title || '');
