import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';

const ButtonEntry = ({
    title,
    linkType,
    linkTypeQuery,
    linkTypePayload,
    styles,
    structureType,
}) => (
    <Button
        attributes={{
            label: title,
            path: {
                type: linkType,
                ...(linkTypePayload && {
                    payload: linkTypePayload,
                }),
                ...(linkTypeQuery && {
                    query: linkTypeQuery,
                }),
            },
        }}
        styles={styles}
        structureType={structureType}
    />
);

ButtonEntry.propTypes = {
    linkType: PropTypes.string,
    linkTypePayload: PropTypes.object,
    linkTypeQuery: PropTypes.object,
    structureType: PropTypes.string,
    styles: PropTypes.object,
    title: PropTypes.string,
};

export default ButtonEntry;
