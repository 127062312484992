import axios from 'axios';

const customLogRequest = ({
    body = {},
    level,
    host = '',
    type = '',
}) => axios.post(`${host}/customlog`, {
    level,
    type,
    body: Buffer.from(
        JSON.stringify(body),
    ).toString('base64'),
});

export default customLogRequest;
