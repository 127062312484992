export function isAbsoluteUrl(url) {
    const urlToCheck = (url && url.type) || url;
    return /^[a-z][a-z0-9+.-]*:/.test(urlToCheck);
}

export function combineUrls(...urls) {
    return urls.join('/').replace(/([^:]\/)\/+/g, '$1');
}

export function addParam(url = '', name = '', value = '') {
    if (!name) return url;
    url += url.includes('?') ? '&' : '?';
    if (!value) {
        url += `${name}`;
    } else {
        url += `${name}=${value}`;
    }
    return url;
}

export function addParams(url = '', list = {}) {
    return Object.keys(list).reduce((acc, key) => addParam(acc, key, list[key]), url);
}

// @TODO Move the following functions into a PopunderUrl Helper, and then refactor withPopunder to get the data it needs and return the object it needs just once before it hits the render
export function getPopCondition(isJoinLink, popunderConfig, blockOptions) {
    const popConditions = [];
    const conditions = popunderConfig.popunders.map(pop => pop.condition);

    if (conditions.includes('all')) {
        popConditions.push('all');
    }

    if (conditions.includes('join') && isJoinLink) {
        popConditions.push('join');
    }

    if (blockOptions && blockOptions.selected && conditions.includes('selectedLink')) {
        popConditions.push('selectedLink');
    }

    return popConditions;
}

export function getPopunderAdUrl(
    programId,
    popunders,
    blockOptions,
    queryParams = {},
    popunderConditions = [],
) {
    const mergedParams = {
        ...(queryParams.ata && {
            ata: Array.isArray(queryParams.ata) ? queryParams.ata.slice(-1)[0] : queryParams.ata,
        }),
        ...(queryParams.ats && {
            ats: Array.isArray(queryParams.ats) ? queryParams.ats.slice(-1)[0] : queryParams.ats,
        }),
    };

    const adSetting = popunders.find(
        config =>
            config.programs.includes(programId) && popunderConditions.includes(config.condition),
    );

    if (!adSetting) {
        return '';
    }

    const adLocation =
        blockOptions && blockOptions.customized ? blockOptions.adLocation : adSetting.adLocation;

    if (!adSetting.shouldIncludeParams || adSetting.shouldIncludeParams === undefined) {
        return adLocation;
    }

    return addParams(adLocation, mergedParams);
}
