import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { get } from 'lodash';
import createMediaRenderer from '../utils/createMediaRenderer';

/**
 * A container component when building a grid
 *
 * Usage:
 * <Container fluid?>
 *     <Row>
 *         <Column>
 *             <... />
 *         </Column>
 *     </Row>
 * </Container>
 */
const Container = styled.div`
    margin-left: auto;
    margin-right: auto;

    ${({ fluid, theme }) => {
        if (fluid) {
            return '';
        }

        const renderer = createMediaRenderer(get(theme, 'grid.breakpoints'));

        return css`
            ${renderer(
                theme.grid.containers,
                width => `
                width: ${width};
                max-width: 100%;
            `,
            )}
        `;
    }}
`;

Container.propTypes = {
    /** Sets the container to fill its available width, alternately it uses breakpoints */
    fluid: PropTypes.bool,
};

Container.displayName = 'Container';

export default Container;
