import React from 'react';

import PropTypes from 'prop-types';
import customLogRequest from '../requests/customLogRequest';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: '' };
    }

    componentDidCatch(err) {
        const { navigationAppUrl, navigationId } = this.props;
        customLogRequest({
            level: 'error',
            host: navigationAppUrl,
            body: {
                error: `${err.name}: ${err.message}`,
                navigationId,
            },
        });
        this.setState({ error: `${err.name}: ${err.message}` });
    }

    render() {
        const { error } = this.state;
        const { children } = this.props;

        if (error) {
            return null;
        }

        return children;
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.node,
    navigationAppUrl: PropTypes.string,
    navigationId: PropTypes.number,
};

export default ErrorBoundary;
