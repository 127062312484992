import {
    getSlug,
    getPiercingQuery,
    queryStringToObject,
    objectToQueryString,
} from './routeUtils';

const SLUG_REPLACER = /\(\[\^\/\][+*]\)\??/;
const SEGMENTS_REPLACER = /(\/:segments\d+\(\[\^\/\]\*?\)\??)+/;
const CUSTOM_LISTS = {
    LIST_FAVORITES: '/list/favorites',
    LIST_HISTORY: '/list/history',
    LIST_WATCHLATER: '/list/watch-later',
    SEARCH_VIDEOS: '/searchvideos',
    SEARCH_MODELS: '/searchmodels',
};

const getPathToRoute = ({ routesMap, path, customPath }) => {
    let route = '';
    if ((!path || path === 'custom') && customPath) {
        route = customPath;
    } else if (path === 'openDrawer') {
        route = '#drawer';
    } else if (path.type) {
        route = CUSTOM_LISTS[path.type] || routesMap[path.type] || '';

        if (path.payload) {
            let slug = '';
            const { actor, release } = path.payload;

            if (actor) {
                route = route.replace(':actorId', actor.id);
                slug = getSlug(actor);
            }
            if (release) {
                route = route.replace(':releaseId', release.id);
                slug = getSlug(release);
            }

            if (SLUG_REPLACER.test(route)) {
                route = route.replace(SLUG_REPLACER, slug);
            }

            Object.entries(path.payload).forEach(([key, value]) => {
                route = route.replace(`:${key}`, value);
            });
        }
    } else if (Object.keys(CUSTOM_LISTS).includes(path)) {
        route = CUSTOM_LISTS[path];
    } else if (routesMap[path]) {
        route = routesMap[path];
    }

    if (route.endsWith('?')) {
        route = route.substring(0, route.length - 1);
    }

    if (SEGMENTS_REPLACER.test(route)) {
        const joinPayload = Object.values(path.payload || {}).join('/');
        route = route.replace(SEGMENTS_REPLACER, '/'.concat(joinPayload)).replace(/\/{2}/g, '/');
    }

    const searchQuery = typeof window !== 'undefined' ? window.location.search : '';
    route += objectToQueryString({
        ...path.query,
        ...getPiercingQuery(queryStringToObject(searchQuery)),
    });

    return route;
};

export {
    getPathToRoute,
};
