import { TOGGLE_ACCORDION, TOGGLE_MOBILE_DRAWER, WINDOW_RESIZE } from './NavigationContext';

const initState = {
    activeAccordionId: null,
    clientWidth: null,
    prevAccordionId: null,
    menuOpen: null,
    user: null,
};

const NavigationReducer = (navigationState, action) => {
    switch (action.type) {
        case TOGGLE_ACCORDION:
            return {
                ...navigationState,
                activeAccordionId:
                    navigationState.activeAccordionId !== action.id ? action.id : null,
                prevAccordionId: navigationState.activeAccordionId,
            };
        case TOGGLE_MOBILE_DRAWER:
            return {
                ...navigationState,
                menuOpen: !navigationState.menuOpen,
                activeAccordionId: null,
                prevAccordionId: null,
            };
        case WINDOW_RESIZE:
            return {
                ...navigationState,
                menuOpen: null,
                activeAccordionId: null,
                prevAccordionId: null,
                clientWidth: action.clientWidth,
            };

        default:
    }
    return navigationState;
};

export default NavigationReducer;
export { initState };
