import React, { useContext, useMemo, Children, cloneElement, memo } from 'react';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { addParam } from '@one/utils';
import {
    NavigationContext,
    TOGGLE_ACCORDION,
    TOGGLE_MOBILE_DRAWER,
} from '../context/NavigationContext';
import FeatherIcon from '../util/FeatherIcon';
import { getPathToRoute } from '../util/getPathToRoute';
import getStyles from '../util/getStyles';
import AccordionContainer from './components/AccordionContainer';
import { getButtonLabel } from './components/ButtonLabel';
import { getButtonDropdown } from './components/ButtonDropdown';

const defaultStyles = {
    button: {
        lineHeight: 1,
        display: 'flex',
        textDecoration: 'none',

        borderWidth: 0,
        borderStyle: 'solid',
        borderColor: 'transparent',
    },
    preIcon: { height: '1em', color: '#dddddd' },
    postIcon: { height: '1em', color: '#dddddd' },
    dropdown: { borderStyle: 'solid', borderColor: 'transparent', borderWidth: 0 },
    label: { display: 'flex', alignItems: 'center', textDecoration: 'none', padding: '4px 8px' },
};

const ButtonComponent = styled.div`
    display: flex;

    &:hover {
        & > a svg,
        & > span svg {
            transform: rotate(${props => props.postIconActiveRotation});
        }
    }

    &.collapsible.open {
        & > span svg {
            transform: rotate(${props => props.postIconActiveRotation});
        }
    }

    a, span {
        display: flex;
        flex: 1;
        align-items: center;
    }

    ${({ styles }) => styles}
`;

const Wrapper = styled.div`
    position: relative;

    & > .dropdown {
        display: none;
    }

    &:hover {
        .dropdown {
            display: block;
        }
    }

    .dynamicGroupsCollapse {
        transition: max-height 300ms;
        overflow: hidden;
    }
`;

const PostIcon = styled(FeatherIcon)(({ styles }) => styles);
const PreIcon = styled(FeatherIcon)(({ styles }) => styles);

const Button = ({
    id,
    children,
    styles = {},
    attributes: {
        label,
        preIcon,
        postIcon,
        path = '',
        shouldOpenNewTab,
        shouldForceRefresh,
        popunder,
        isNoFollow,
        customPath,
        postIconActiveRotation,
        fontSelection = '',
        autoLogin,
        autoLoginUrl,
    },
    structureType,
    isChildButton,
    ...props
}) => {
    const {
        dispatch,
        onHelmet,
        onFlirt4FreeLink,
        routesMap,
        state: { activeAccordionId, prevAccordionId, menuOpen },
        user: { boxToken = '' } = {},
    } = useContext(NavigationContext);
    const hasChildren = typeof children === 'object' && !isEmpty(children);
    const isMobileCollapsible = hasChildren && structureType === 'mobile';
    const isDesktopCollapsible = hasChildren && structureType === 'desktop';
    const isOpen = activeAccordionId === id || String(activeAccordionId).startsWith(id);
    const wasOpen = prevAccordionId === id;
    const isClickableButton = structureType === 'mobile' && (!hasChildren || isChildButton);

    const { rootStyles = {}, rootHoverStyles = {}, childrenStyles = {} } = useMemo(() => getStyles(styles));

    const Dropdown = getButtonDropdown({
        ...defaultStyles.dropdown,
        ...childrenStyles.dropdown,
        ...(isChildButton && { position: 'relative', top: '0%' }),
    });

    const accorditionStyles = {
        ...defaultStyles.dropdown,
        ...childrenStyles.dropdown,
    };

    const Label = getButtonLabel(isMobileCollapsible, {
        ...defaultStyles.label,
        ...childrenStyles.label,
    });

    const handleClick = () => {
        if (isClickableButton && menuOpen) {
            onHelmet({ previous: true, next: false });
            dispatch({ type: TOGGLE_MOBILE_DRAWER });
        }
        if (isMobileCollapsible) {
            dispatch({ type: TOGGLE_ACCORDION, id });
        }

        if (shouldForceRefresh && typeof window !== 'undefined') {
            window.location.reload();
        }
        if (path === 'flirt4Free' && typeof onFlirt4FreeLink === 'function') {
            onFlirt4FreeLink({ shouldOpenNewTab });
        }
    };

    const nextChildren = children
        ? Children.map(children, child => cloneElement(child, { isChildButton: true }))
        : null;

    const postIconStyles = { ...defaultStyles.postIcon, ...childrenStyles.postIcon };
    const preIconStyles = { ...defaultStyles.preIcon, ...childrenStyles.preIcon };

    const buttonStyles = {
        ...defaultStyles.button,
        ...rootStyles,
        ...(isChildButton && hasChildren && { flexDirection: 'column' }),
        ...(isMobileCollapsible && { flexDirection: 'column', alignItems: 'stretch' }),
        ...(structureType === 'mobile' && { flexShrink: 0 }),
        '&:hover >': {
            ...rootStyles['&:hover'],
            [Label]: {
                ...rootHoverStyles.label,
                [PostIcon]: rootHoverStyles.postIcon,
                [PreIcon]: rootHoverStyles.preIcon,
            },
        },
    };

    const buttonUrl = (autoLogin
        && addParam(autoLoginUrl, 'token', boxToken))
        || getPathToRoute({ routesMap, path, customPath });

    return (
        <Wrapper>
            <ButtonComponent
                id={`btn_${id}`}
                key={`btn_${id}`}
                onClick={handleClick}
                postIconActiveRotation={postIconActiveRotation}
                {...props}
                className={`${fontSelection} ${isMobileCollapsible ? 'collapsible' : ''} ${
                    isOpen ? 'open' : ''
                }`}
                styles={buttonStyles}
            >
                <Label
                    to={buttonUrl}
                    popunder={popunder}
                    {...(shouldOpenNewTab && { target: '_blank' })}
                    {...(isNoFollow && { rel: 'nofollow' })}
                >
                    <PreIcon icon={preIcon} styles={preIconStyles} />
                    {' '}
                    {label}
                    <PostIcon icon={postIcon} styles={postIconStyles} />
                </Label>
            </ButtonComponent>
            {isMobileCollapsible && (
                <AccordionContainer
                    id={`AccordionContainer_${id}`}
                    isOpen={isOpen}
                    wasOpen={wasOpen}
                    styles={accorditionStyles}
                >
                    {nextChildren}
                </AccordionContainer>
            )}
            {isDesktopCollapsible && <Dropdown className="dropdown">{nextChildren}</Dropdown>}
        </Wrapper>
    );
};

Button.propTypes = {
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    styles: PropTypes.object,
    children: PropTypes.node,
    attributes: PropTypes.object,
    structureType: PropTypes.string,
    isChildButton: PropTypes.bool,
};

export default memo(Button);
