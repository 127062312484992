import axios from 'axios';

const loadNavigation = ({ navigationApiUrl, navigationId }) => {
    const url = `${navigationApiUrl}/v1/navbar/${navigationId}`;
    return axios.get(url)
        .then(res => (res.data && res.data.data))
        .catch(() => ({}));
};

export default loadNavigation;
