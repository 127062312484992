import React, { useContext, useReducer, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { mediaUp } from '@one/theme-utils';
import { NavigationContext } from '../../context/NavigationContext';

const defaultStyles = {
    top: 0,
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    lineHeight: 1,
    textDecoration: 'none',
    zIndex: 102, // catfish 101 < Nav < PPP bar 500
};

const Nav = styled.nav`
    ${({ structureType }) => {
        if (structureType === 'mobile') {
            return `
                right: 0;
                left: 0;
                transform: rotate(360deg);
            `;
        }

        return `
            left: 50%;
            transform: translateX(-50%);
        `;
    }}

    ${({
        isContentFullWidth,
        theme: { grid: { gutter } = {} } = {},
    }) => (isContentFullWidth
        ? mediaUp('medium')`
        padding: 0 calc(0.5 * ${gutter});
    `
        : '')}

    transition: background 0.5s, background-color 0.5s;

    ${({ styles = {} }) => styles}
`;

const initReducer = state => {
    const {
        nav,
        initValues: { background, backgroundColor, toggleOnScroll },
    } = state;

    const parsedBackground = background && background.replace(';', '');
    const parsedBackgroundColor = backgroundColor && backgroundColor.replace(';', '');

    return {
        ...state,
        nav: {
            ...nav,
            background: background ? parsedBackground : parsedBackgroundColor,
        },
        initValues: {
            ...state.initValues,
            toggleOnScroll: background && toggleOnScroll,
            background: parsedBackground,
            backgroundColor: parsedBackgroundColor,
        },
    };
};

const stateReducer = (
    { nav, wrapper, initValues, ...state },
    { isSticky, isContentPushed, navRef },
) => {
    if (isSticky && navRef.current) {
        const { background: initBackground, backgroundColor, toggleOnScroll } = initValues;

        return {
            ...state,
            nav: {
                ...nav,
                position: 'fixed',
                background: toggleOnScroll ? backgroundColor : initBackground,
            },
            wrapper: {
                ...wrapper,
                paddingBottom:
                    !isContentPushed
                        ? navRef.current.clientHeight
                        : 0,
                marginBottom:
                // eslint-disable-next-line no-nested-ternary
                        isContentPushed
                            ? navRef.current.clientHeight
                            : !isContentPushed
                                ? -navRef.current.clientHeight
                                : 0,
            },
            initValues,
        };
    }

    return { ...state, wrapper, nav, initValues };
};

const NavComponent = ({
    styles: { position = 'relative', background, backgroundColor, ...styles } = {},
    isContentPushed,
    isSticky,
    toggleOnScroll,
    isContentFullWidth,
    structureType,
    children,
}) => {
    const [styleState, setStyleState] = useReducer(
        stateReducer,
        {
            nav: { position },
            wrapper: { paddingBottom: 0, marginBottom: 0 },
            initValues: { background, backgroundColor, toggleOnScroll },
        },
        initReducer,
    );

    const { state: { clientWidth = 0 } = {} } = useContext(NavigationContext);
    const navRef = useRef();

    useEffect(() => {
        setStyleState({
            isSticky,
            isContentPushed,
            navRef,
        });
    }, [isSticky, isContentPushed, navRef]);

    const width = structureType !== 'mobile' && clientWidth;
    const { nav, wrapper } = styleState;

    return (
        <div style={wrapper}>
            <Nav
                ref={navRef}
                style={{ ...nav, width }}
                styles={{ ...defaultStyles, ...styles }}
                isContentFullWidth={isContentFullWidth}
                structureType={structureType}
                className="nav-component"
            >
                {children}
            </Nav>
        </div>
    );
};

NavComponent.propTypes = {
    styles: PropTypes.object,
    isContentPushed: PropTypes.bool,
    isContentFullWidth: PropTypes.bool,
    isSticky: PropTypes.bool,
    structureType: PropTypes.string,
    toggleOnScroll: PropTypes.bool,
    children: PropTypes.node,
};

export default NavComponent;
