const getCookie = cookieName => {
    let cookieObj = {};
    const hasDocument = typeof document !== 'undefined' && typeof document.cookie === 'string';
    if (hasDocument) {
        cookieObj = document.cookie.split('; ').reduce((acc, item) => {
            const [key, value] = item.split('=');
            acc[key] = value;
            return acc;
        }, {});
    }

    return cookieName ? cookieObj[cookieName] : cookieObj;
};

export default getCookie;
