import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const defaultStyles = {
    lineHeight: 1,
    textDecoration: 'none',
    display: 'flex',
};

const typeStyles = {
    center: {
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
    },
    top: {
        flexDirection: 'column',
        overflowY: 'auto',
    },
    left: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    right: {
        marginLeft: 'auto',
        alignItems: 'center',
    },
    bottom: {
        marginTop: 'auto',
        flexDirection: 'column',
        flexShrink: 0,
    },
    justify: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'stretch',
        flex: 1,
        '& img': { maxWidth: '100% !important' },
        '& > *': {
            flex: 1,
            flexShrink: '1 !important',
        },
    },
    spaceBetween: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        flex: 1,
    },
    default: { flex: 1 },
};
const ContainerAlingmentComponent = styled.div(({ styles }) => styles);

const ContainerAlingment = ({
    attributes: { alignment = 'default' },
    children,
    styles = {},
    ...props
}) => (
    <ContainerAlingmentComponent
        {...props}
        styles={{ ...defaultStyles, ...typeStyles[alignment], ...styles }}
    >
        {children}
    </ContainerAlingmentComponent>
);

ContainerAlingment.propTypes = {
    attributes: PropTypes.object,
    children: PropTypes.node,
    styles: PropTypes.object,
};

export default ContainerAlingment;
