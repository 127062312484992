import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { get } from 'lodash';
import createMediaRenderer from '../utils/createMediaRenderer';
import getSpanWidth from '../utils/getSpanWidth';
import getSpanSizes from '../utils/getSpanSizes';

import Row from './Row';

const GridRow = styled(Row)`
    margin-bottom: -${props => get(props, 'theme.grid.gutter', '10px')};
    display: flex;
`;
GridRow.displayName = 'GridRow';

// TODO: Filter props automatically when styled-components supports it
// eslint-disable-next-line no-unused-vars
const GridItem = styled(({ overrideCols, type, theme, ...props }) => <div {...props} />)`
    position: relative;
    min-height: 1px;
    width: 100%;
    padding-left: ${props =>
        get(props, 'theme.gutter.pageGutter') || get(props, 'theme.grid.gutter', '10px')};
    margin-bottom: ${props =>
        get(props, 'theme.gutter.pageGutter') || get(props, 'theme.grid.gutter', '10px')};

    ${({ overrideCols, type, theme }) => {
        const span = getSpanSizes(theme, type, overrideCols);
        const renderer = createMediaRenderer(get(theme, 'grid.breakpoints', {}));
        return css`
            ${span &&
                renderer(span, spanBy => {
                    const width = getSpanWidth(spanBy, get(theme, 'grid.size', 6));
                    return `
                    flex: 0 0 ${width};
                    max-width: ${width};
                `;
                })}
        `;
    }}
`;
GridItem.displayName = 'GridItem';

class GridItems extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        type: PropTypes.string,
        children: PropTypes.node,
    };

    render() {
        const { className, type, children, overrideCols } = this.props;

        // TODO: Review this performance killer
        // const trimmedChildren = Children.toArray(children).slice(0, limit);

        return (
            <GridRow className={className}>
                {children.map((child, index) => (
                    <GridItem key={index} type={type} overrideCols={overrideCols}>
                        {child}
                    </GridItem>
                ))}
            </GridRow>
        );
    }
}
GridItems.displayName = 'GridItems';

export default GridItems;
