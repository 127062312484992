import React from 'react';
import PropTypes from 'prop-types';
import NavigationComponents from '../component';

const FallbackElement = ({ children }) => <div>{children}</div>;
FallbackElement.propTypes = { children: PropTypes.node };

const getElementComponent = nodeName => NavigationComponents[nodeName] || FallbackElement;
const getElementStyles = (styles, id) => (styles && styles[id]) || {};

const renderElements = ({
    isPreview,
    structureType,
    structure: elements,
    styles,
}) => elements.map(
    ({ id, attributes = {}, children, nodeName, styleId }, index) => {
        const elemId = id || `${styleId}_${index}`;
        const Element = getElementComponent(nodeName);
        const hasChildren = Array.isArray(children) && children.length > 0;

        return (
            <Element
                id={elemId}
                key={elemId}
                isPreview={isPreview}
                attributes={attributes}
                className={!id && !index && structureType}
                structureType={structureType}
                styles={getElementStyles(styles, styleId)}
            >
                {hasChildren
                        && renderElements({
                            isPreview,
                            structureType,
                            structure: children,
                            styles,
                        })}
            </Element>
        );
    },
);

export default renderElements;
