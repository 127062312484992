import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FeatherIcon from '../../util/FeatherIcon';
import getStyles from '../../util/getStyles';

const InitComponent = styled.div`
    cursor: pointer;
    display: flex;
    span {
        display: flex;
        flex: 1;
        align-items: center;
    }
`;

const defaultStyles = {
    button: {
        lineHeight: 1,
        textDecoration: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',

        borderStyle: 'solid',
        borderColor: 'transparent',
        borderWidth: 0,
    },
    label: { display: 'flex', alignItems: 'center', textDecoration: 'none', padding: '4px 8px' },
    preIcon: { height: '1em' },
    postIcon: { height: '1em' },
    dropdown: { borderStyle: 'solid', borderColor: 'transparent', borderWidth: 0 },
};

const DrawerSearchButton = ({
    attributes: { label, preIcon, postIcon, fontSelection = '' } = {},
    styles = {},
    ...props
}) => {
    const { rootStyles = {}, rootHoverStyles = {}, childrenStyles = {} } = getStyles(styles);

    const Label = styled.span({
        ...defaultStyles.label,
        ...childrenStyles.label,
    });

    const PostIcon = styled(FeatherIcon)({ ...defaultStyles.postIcon, ...childrenStyles.postIcon });
    const PreIcon = styled(FeatherIcon)({ ...defaultStyles.preIcon, ...childrenStyles.preIcon });

    const StyledComponent = styled(InitComponent)({
        ...defaultStyles.button,
        ...rootStyles,

        '&:hover >': {
            ...rootStyles['&:hover'],
            [Label]: {
                ...rootHoverStyles.label,
                [PostIcon]: rootHoverStyles.postIcon,
                [PreIcon]: rootHoverStyles.preIcon,
            },
        },
        '.collapse': { ...childrenStyles.dropdown },
    });

    return (
        <StyledComponent {...props} className={fontSelection}>
            <Label>
                <PreIcon icon={preIcon} />
                {' '}
                {label}
                {' '}
                <PostIcon icon={postIcon} />
            </Label>
        </StyledComponent>
    );
};
DrawerSearchButton.propTypes = {
    attributes: PropTypes.object,
    styles: PropTypes.object,
};

export default memo(DrawerSearchButton);
