import React from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';

import { colors } from '@one/theme-utils';

const Wrapper = styled.div``;

const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const Spin = styled.div`
    border-radius: 50%;
    font-size: 15px;
    font-weight: bold;
    width: ${props => (props.size ? props.size : '6em')};
    height: ${props => (props.size ? props.size : '6em')};
    margin: ${props => (props.margin ? props.margin : '100px')} auto;
    position: relative;
    border-top: 0.5em solid rgba(200, 200, 200, 0);
    border-right: 0.5em solid rgba(200, 200, 200, 0);
    border-bottom: 0.5em solid ${props => props.color || colors('primary')};
    border-left: 0.5em solid ${props => props.color || colors('primary')};
    transform: translateZ(0);
    animation: ${spin} 0.7s infinite linear;
`;

const Spinner = ({ color, size, margin, className }) => (
    <Wrapper className={className}>
        <Spin color={color} size={size} margin={margin} />
    </Wrapper>
);

Spinner.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    margin: PropTypes.string,
    size: PropTypes.string,
};

export default Spinner;
