import {
    getBreakpointMin,
    getBreakpointMax,
    breakpointAlways,
    breakpointDown,
    breakpointUp,
    breakpointBetween,
    breakpointExclude,
} from './breakpoints';

/**
 * Media of at least the minimum breakpoint width. (No query for the smallest breakpoint.)
 *
 * @param  {String}   name        Breakpoint name
 * @param  {Object}   breakpoints Breakpoints map
 * @return {function}             Tagged template literal
 */
export function mediaUp(name, breakpoints = {}) {
    const min = getBreakpointMin(name, breakpoints);

    if (min === 0) {
        return breakpointAlways();
    }

    return breakpointUp(min);
}

/**
 * Media of at most the maximum breakpoint width. (No query for the largest breakpoint.)
 *
 * @param  {String}   name        Breakpoint name
 * @param  {Object}   breakpoints Breakpoints map
 * @return {function}             Tagged template literal
 */
export function mediaDown(name, breakpoints = {}) {
    const max = getBreakpointMax(name, breakpoints);

    if (max === Infinity) {
        return breakpointAlways();
    }

    return breakpointDown(max);
}

/**
 * Media of a range between two breakpoints
 *
 * @param  {String}   lower       Lower breakpoint name
 * @param  {String}   upper       Upper breakpoint name
 * @param  {Object}   breakpoints Breakpoints map
 * @return {function}             Tagged template literal
 */
export function mediaBetween(lower, upper, breakpoints = {}) {
    const min = getBreakpointMin(lower, breakpoints);
    const max = getBreakpointMax(upper, breakpoints);

    if (min === 0 && max === Infinity) {
        return breakpointAlways();
    }

    if (min === 0) {
        return breakpointDown(max);
    }

    if (max === Infinity) {
        return breakpointUp(min);
    }

    return breakpointBetween(min, max);
}

/**
 * Media excluding a range between two breakpoints
 *
 * @param  {String}   lower       Lower breakpoint name
 * @param  {String}   upper       Upper breakpoint name
 * @param  {Object}   breakpoints Breakpoints map
 * @return {function}             Tagged template literal
 */
export function mediaExclude(lower, upper, breakpoints = {}) {
    const min = getBreakpointMin(lower, breakpoints);
    const max = getBreakpointMax(upper, breakpoints);

    if (min === 0 && max === Infinity) {
        return () => '';
    }

    if (min === 0) {
        return breakpointUp(max + 1);
    }

    if (max === Infinity) {
        return breakpointDown(min - 1);
    }

    // Overwrites 1px compared to mediaBetween, avoids overlap media queries
    return breakpointExclude(min - 1, max + 1);
}

/**
 * Media excluding a single breakpoint
 *
 * @param  {String}   name        Breakpoint name
 * @param  {Object}   breakpoints Breakpoints map
 * @return {function}             Tagged template literal
 */
export function mediaExcludeOnly(name, breakpoints = {}) {
    return mediaExclude(name, name, breakpoints);
}

/**
 * Media of a single breakpoint
 *
 * @param  {String}   name        Breakpoint name
 * @param  {Object}   breakpoints Breakpoints map
 * @return {function}             Tagged template literal
 */
export function mediaOnly(name, breakpoints = {}) {
    return mediaBetween(name, name, breakpoints);
}
