import PropTypes from 'prop-types';
import { isFunction } from 'lodash';

const imageLoader = ({
    image = {},
    onload,
    onerror,
} = {}) => {
    const loader = new Image();

    loader.onload = () => {
        if (isFunction(onload)) {
            onload(loader);
        }
    };

    loader.onerror = () => {
        if (isFunction(onerror)) {
            onerror();
        }
    };

    loader.src = image.src;
};

imageLoader.propTypes = {
    image: PropTypes.shape({
        src: PropTypes.string.isRequired,
    }),
    onload: PropTypes.func,
    onerror: PropTypes.func,
};

export default imageLoader;
