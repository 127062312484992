import React, { useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

import loadNavigation from '../requests/loadNavigation';
import { NavigationContext } from '../context/NavigationContext';
import NavigationReducer, { initState } from '../context/NavigationReducer';
import NavigationContainer from './NavigationContainer';

import useWindowSize from '../util/useWindowSize';
import validateParams from '../util/validateParams';
import ErrorBoundary from './ErrorBoundary';

const STRUCTURE_DESKTOP = 'desktop';
const STRUCTURE_MOBILE = 'mobile';

const App = ({
    otherProps: {
        navigationApiUrl,
        navigationAppUrl,
        blockId,
        blockName,
        dynamicContent = {},
        isPhone = false,
        instance: {
            navigationConfig = {},
            showFirstNamesOnly = false,
        } = {},
        mobileBreakPoint = 999,
        navigationId,
        paths,
        pageType,
        onHelmet,
        onLink,
        onFlirt4FreeLink,
        onSearch,
        onSearchRedirect,
        onSticky,
        preStructure = null,
        query = {},
        referrerHeaders = {},
        result = {},
        routesMap = {},
        theme = {},
        user = {},
    } = {},
}) => {
    validateParams([
        { name: 'navigationApiUrl', type: 'string', value: navigationApiUrl },
        { name: 'navigationAppUrl', type: 'string', value: navigationAppUrl },
        { name: 'navigationId', type: 'number', value: navigationId },
        { name: 'onHelmet', type: 'function', value: onHelmet },
        { name: 'onLink', type: 'function', value: onLink },
        { name: 'onSearch', type: 'function', value: onSearch }, // deprecated in SearchBoxV2
        { name: 'onSearchRedirect', type: 'function', value: onSearchRedirect },
        { name: 'onSticky', type: 'function', value: onSticky },
        { name: 'referrerHeaders', type: 'object', value: referrerHeaders },
    ]);

    const isPreview = Boolean(preStructure);

    const [structure, setStructure] = useState(preStructure);
    const [state, dispatch] = useReducer(NavigationReducer, initState);

    const { width } = useWindowSize({ wait: 250 });
    const collapseToMobile = width && width < mobileBreakPoint;
    const structureType = isPhone || (!isPhone && collapseToMobile) ? STRUCTURE_MOBILE : STRUCTURE_DESKTOP;

    const menuActions = { state, dispatch };

    const providerValues = {
        ...menuActions,
        dynamicContent,
        blockId,
        blockName,
        referrerHeaders,
        routesMap,
        navigationApiUrl,
        navigationConfig,
        pageType,
        onHelmet,
        onLink,
        onFlirt4FreeLink,
        onSearch,
        onSearchRedirect,
        query,
        paths,
        result,
        showFirstNamesOnly,
        user,
    };

    const errorBoundaryContext = {
        navigationId,
        navigationAppUrl,
    };

    useEffect(() => {
        if (preStructure && preStructure !== structure) {
            setStructure(preStructure);
        }
    }, [preStructure]);

    useEffect(() => {
        if (!structure && !preStructure) {
            loadNavigation({ navigationApiUrl, navigationId }).then((resp = {}) => {
                const { structure: blob = '' } = resp;
                if (blob) {
                    const parsed = JSON.parse(blob);
                    setStructure(parsed);
                }
            });
        }
    }, []);

    useEffect(() => {
        let timeout;
        if (structure) {
            // timeout from NavComponent useIsPPPBarOffFold() hook
            timeout = setTimeout(() => {
                const element = document.querySelector('.nav-component') || {};
                const { clientHeight = 0, style: { position } = {} } = element;
                const isSticky = position === 'fixed';
                if (isSticky && onSticky) {
                    onSticky({ navHeight: clientHeight });
                }
            }, 50);
        }

        return () => {
            clearTimeout(timeout);
        };
    }, [structure]);

    return structure ? (
        <ErrorBoundary {...errorBoundaryContext}>
            <NavigationContext.Provider value={providerValues}>
                <ThemeProvider theme={theme}>
                    <NavigationContainer
                        className={structureType}
                        menuActions={menuActions}
                        isPreview={isPreview}
                        structure={structure}
                        structureType={structureType}
                    />
                </ThemeProvider>
            </NavigationContext.Provider>
        </ErrorBoundary>
    ) : null;
};

App.propTypes = {
    otherProps: PropTypes.shape({
        navigationApiUrl: PropTypes.string.isRequired,
        navigationAppUrl: PropTypes.string.isRequired,
        blockId: PropTypes.number,
        blockName: PropTypes.string,
        dynamicContent: PropTypes.object,
        isPhone: PropTypes.bool,
        instance: PropTypes.shape({
            navigationConfig: PropTypes.object,
            showFirstNamesOnly: PropTypes.bool,
        }),
        mobileBreakPoint: PropTypes.number,
        navigationId: PropTypes.number.isRequired,
        pageType: PropTypes.string,
        paths: PropTypes.object,
        onHelmet: PropTypes.func.isRequired,
        onLink: PropTypes.func.isRequired,
        onSearch: PropTypes.func.isRequired,
        onSearchRedirect: PropTypes.func.isRequired,
        onFlirt4FreeLink: PropTypes.func.isRequired,
        onSticky: PropTypes.func.isRequired,
        query: PropTypes.object,
        referrerHeaders: PropTypes.object,
        routesMap: PropTypes.object,
        theme: PropTypes.object,
        user: PropTypes.shape({
            isNSF: PropTypes.bool,
            isExpired: PropTypes.bool,
            boxToken: PropTypes.string,
            memberUuid: PropTypes.string,
        }),
    }).isRequired,
};

export default App;
