import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const defaultStyles = {
    lineHeight: 1,
    textDecoration: 'none',
    display: 'flex',
    position: 'relative',
    flex: 1,
    width: '100%',
};

const ContainerComponent = styled.div(({ styles }) => styles);

const Container = ({ styles = {}, children, ...props }) => (
    <ContainerComponent {...props} styles={{ ...defaultStyles, ...styles }}>
        {children}
    </ContainerComponent>
);

Container.propTypes = {
    children: PropTypes.node,
    styles: PropTypes.object,
};

export default Container;
