/* eslint-disable no-console */
import dynamicContent from './dynamicContent.mock';
import mockRoutes from './routes.mock';
import mockTheme from './theme.mock';

// Updating React-Helmet to prevent scrolling when Mobile menu is open
const onHelmet = params => {
    console.log('%conHelmet', 'color:#0b0', params);
    document.body.style.overflow = params.next ? 'hidden' : 'auto';
};
// Redirecting links
const onLink = params => {
    console.log('%conLink', 'color:#0b0', params);
};

const onFlirt4FreeLink = params => {
    console.log('%onFlirt4FreeLink', 'color:#0b0', params);
};

// Launching a search query to BE
const onSearch = params => {
    console.log('%conSearch', 'color:#0b0', params);
};
// Redirecting when clicking a search result
const onSearchRedirect = params => {
    console.log('%conSearchRedirect', 'color:#0b0', params);
};
// Registering sticky nav
const onSticky = params => {
    console.log('%conSticky', 'color:#0b0', params);
};

// Ad tracking
const validQueryKeys = ['ad_id'];
const regExp = new RegExp(`(${validQueryKeys.join('|')})=([^?&#]+)`, 'g');
const queryReg = [...(window.location.search || '').matchAll(regExp)];
const query = queryReg.reduce((acc, [, key, value]) => ({
    ...acc,
    [key]: value,
}), {});

const mockProps = {
    dynamicContent,
    instance: {
        navigationConfig: {
            desktop: {
                path: 'home',
            },
            mobile: {
                path: 'home',
            },
        },
        showFirstNamesOnly: false,
    },
    isPhone: false,
    mobileBreakPoint: 999,
    // stage
    // navigationApiUrl: 'https://stage10-navigation-api.project1service.com',
    // navigationAppUrl: 'https://stage10-one-navigation.project1service.com',
    // preprod
    // navigationApiUrl: 'https://preprod-navigation-api.project1service.com',
    // navigationAppUrl: 'https://preprod-one-navigation.project1service.com',
    // prod
    navigationApiUrl: 'https://navigation-api.project1service.com',
    navigationAppUrl: 'https://onenavigation-ht.project1content.com',
    navigationId: 42,
    onHelmet,
    onLink,
    onFlirt4FreeLink,
    onSearch,
    onSearchRedirect,
    onSticky,
    pageType: 'EXPLORE_SCENES',
    query,
    routesMap: mockRoutes,
    theme: mockTheme,
    paths: {
        notification: {
            type: 'ACCOUNT',
            query: {
                tab: 'billing',
            },
        },
    },
    user: {
        boxToken: '',
        isExpired: false,
        isLoyaltyActive: false,
        isNSF: false,
        loyalty: {
            pointBalance: undefined,
        },
        memberUuid: '6fea72cf-bda9-4267-ba9d-a155d254630b',
    },
};

export default mockProps;
