import styled from 'styled-components';

import Link from '../../util/Link';

const AccordionLabel = styled.span``;

export const getButtonLabel = (isAccordion, styles) => {
    const Label = isAccordion ? AccordionLabel : Link;
    return styled(Label)(styles);
};
