import React from 'react';
import { parseJsonSafe } from '@one/utils';

export function extractMediaPath(media, webP) {
    if (typeof media === 'object') {
        const { urls, url } = media;

        /* we can not use jpeg in place of gif, png as it does
           not support transparency. WebP supports transparency
           but there is an issue with the image service so gif, png won't
           be converted to WebP correctly for the time being.
        */
        const supportsTransparency = /\.(gif|png)$/i.test(url);
        if (supportsTransparency) {
            return url;
        }

        if (urls && urls.webp && webP) {
            return urls.webp;
        } else if (urls && urls.default) {
            // urls.default is always .jpg
            return urls.default;
        } else if (url) {
            // url is the original media uploaded
            return url;
        }
    }

    return media;
}

export function getMediaSrc(media, webP) {
    let mediaObj;

    // @TODO : temporary fix for bad data (single quote json)
    if (typeof media === 'string') {
        media = media.replace(/'/g, '"'); // eslint-disable-line no-useless-escape
    }

    if (typeof media === 'object' && !React.isValidElement(media)) {
        mediaObj = media;
    } else {
        mediaObj = parseJsonSafe(media);
    }

    if (mediaObj) {
        return extractMediaPath(mediaObj, webP);
    }

    if (typeof media === 'function') {
        return (...args) => getMediaSrc(media(...args), webP);
    }

    return media;
}
