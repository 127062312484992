const mockUser = {
    boxToken: 'alpha-beta-token',
    memberId: 268859741,
    banningAdviceCode: null,
    consentRequired: null,
    country: 'US',
    currency: 'USD',
    email: 'P1QA_11_bkzqpc@engpaysites.mindgeek.com',
    expiryDate: '2023-03-29T15:05:33+00:00',
    initialAmount: 31.93,
    initialDays: 30,
    isEmailVerified: false,
    isExpired: false,
    isInGracePeriod: false,
    isNg: true,
    isTrial: false,
    isNSF: false,
    isMarketplace: true,
    joinDate: '2023-02-27T15:05:35+00:00',
    lastLoginTime: '2023-03-06T14:12:26+00:00',
    loginCount: 9,
    memberUuid: '6fea72cf-bda9-4267-ba9d-a155d254630b',
    nickname: 'P1QA_11_bkzqpc',
    isPrepaid: false,
    isLowRiskBin: true,
    rebillAmount: 31.93,
    rebillDays: 30,
    siteUuid: '8e34c94e-135f-4acb-9141-58b3a6e56c74',
    streamateUrl: null,
    subscriptionId: 309349871,
    username: 'P1QA_11_bkzqpc',
    videoQuality: '',
    ppp: null,
    streamateSaKey: null,
    streamateStatusCode: null,
    memberUnlocksCount: 0,
    marketplace: {
        timers: [
            {
                desktop: {
                    isEnabled: false,
                    fontFamily: 'Arial',
                    fontSize: 12,
                    hours: {
                        top: 0,
                        left: 0,
                        color: '#000000',
                    },
                    minutes: {
                        top: 0,
                        left: 0,
                        color: '#000000',
                    },
                    seconds: {
                        top: 0,
                        left: 0,
                        color: '#000000',
                    },
                },
                tablet: {
                    isEnabled: false,
                    fontFamily: 'Arial',
                    fontSize: 12,
                    hours: {
                        top: 0,
                        left: 0,
                        color: '#000000',
                    },
                    minutes: {
                        top: 0,
                        left: 0,
                        color: '#000000',
                    },
                    seconds: {
                        top: 0,
                        left: 0,
                        color: '#000000',
                    },
                },
                mobile: {
                    isEnabled: false,
                    fontFamily: 'Arial',
                    fontSize: 12,
                    hours: {
                        top: 0,
                        left: 0,
                        color: '#000000',
                    },
                    minutes: {
                        top: 0,
                        left: 0,
                        color: '#000000',
                    },
                    seconds: {
                        top: 0,
                        left: 0,
                        color: '#000000',
                    },
                },
                endTime: null,
            },
            {
                desktop: {
                    isEnabled: false,
                    fontFamily: 'Arial',
                    fontSize: 12,
                    hours: {
                        top: 0,
                        left: 0,
                        color: '#000000',
                    },
                    minutes: {
                        top: 0,
                        left: 0,
                        color: '#000000',
                    },
                    seconds: {
                        top: 0,
                        left: 0,
                        color: '#000000',
                    },
                },
                tablet: {
                    isEnabled: false,
                    fontFamily: 'Arial',
                    fontSize: 12,
                    hours: {
                        top: 0,
                        left: 0,
                        color: '#000000',
                    },
                    minutes: {
                        top: 0,
                        left: 0,
                        color: '#000000',
                    },
                    seconds: {
                        top: 0,
                        left: 0,
                        color: '#000000',
                    },
                },
                mobile: {
                    isEnabled: false,
                    fontFamily: 'Arial',
                    fontSize: 12,
                    hours: {
                        top: 0,
                        left: 0,
                        color: '#000000',
                    },
                    minutes: {
                        top: 0,
                        left: 0,
                        color: '#000000',
                    },
                    seconds: {
                        top: 0,
                        left: 0,
                        color: '#000000',
                    },
                },
                endTime: null,
            },
            {
                desktop: {
                    isEnabled: false,
                    fontFamily: 'Arial',
                    fontSize: 12,
                    hours: {
                        top: 0,
                        left: 0,
                        color: '#000000',
                    },
                    minutes: {
                        top: 0,
                        left: 0,
                        color: '#000000',
                    },
                    seconds: {
                        top: 0,
                        left: 0,
                        color: '#000000',
                    },
                },
                tablet: {
                    isEnabled: false,
                    fontFamily: 'Arial',
                    fontSize: 12,
                    hours: {
                        top: 0,
                        left: 0,
                        color: '#000000',
                    },
                    minutes: {
                        top: 0,
                        left: 0,
                        color: '#000000',
                    },
                    seconds: {
                        top: 0,
                        left: 0,
                        color: '#000000',
                    },
                },
                mobile: {
                    isEnabled: false,
                    fontFamily: 'Arial',
                    fontSize: 12,
                    hours: {
                        top: 0,
                        left: 0,
                        color: '#000000',
                    },
                    minutes: {
                        top: 0,
                        left: 0,
                        color: '#000000',
                    },
                    seconds: {
                        top: 0,
                        left: 0,
                        color: '#000000',
                    },
                },
                endTime: null,
            },
            {
                desktop: {
                    isEnabled: false,
                    fontFamily: 'Arial',
                    fontSize: 12,
                    hours: {
                        top: 0,
                        left: 0,
                        color: '#000000',
                    },
                    minutes: {
                        top: 0,
                        left: 0,
                        color: '#000000',
                    },
                    seconds: {
                        top: 0,
                        left: 0,
                        color: '#000000',
                    },
                },
                tablet: {
                    isEnabled: false,
                    fontFamily: 'Arial',
                    fontSize: 12,
                    hours: {
                        top: 0,
                        left: 0,
                        color: '#000000',
                    },
                    minutes: {
                        top: 0,
                        left: 0,
                        color: '#000000',
                    },
                    seconds: {
                        top: 0,
                        left: 0,
                        color: '#000000',
                    },
                },
                mobile: {
                    isEnabled: false,
                    fontFamily: 'Arial',
                    fontSize: 12,
                    hours: {
                        top: 0,
                        left: 0,
                        color: '#000000',
                    },
                    minutes: {
                        top: 0,
                        left: 0,
                        color: '#000000',
                    },
                    seconds: {
                        top: 0,
                        left: 0,
                        color: '#000000',
                    },
                },
                endTime: null,
            },
        ],
        assets: [
            {
                url: {
                    desktop: '//static2-ppp.portalcdn.com/hd/mkt/prod/cp/as/desktop_166844305154626.jpg',
                    tablet: '//static2-ppp.portalcdn.com/hd/mkt/prod/cp/as/tablet_166844305129127.jpg',
                    mobile: '//static2-ppp.portalcdn.com/hd/mkt/prod/cp/as/mobile_166844305196143.jpg',
                },
                productPageId: 1901,
                title: 'Anal Channel ',
                bundleId: 7112,
                timer: {
                    desktop: {
                        isEnabled: false,
                        fontFamily: 'Arial',
                        fontSize: 12,
                        hours: {
                            top: 0,
                            left: 0,
                            color: '#000000',
                        },
                        minutes: {
                            top: 0,
                            left: 0,
                            color: '#000000',
                        },
                        seconds: {
                            top: 0,
                            left: 0,
                            color: '#000000',
                        },
                    },
                    tablet: {
                        isEnabled: false,
                        fontFamily: 'Arial',
                        fontSize: 12,
                        hours: {
                            top: 0,
                            left: 0,
                            color: '#000000',
                        },
                        minutes: {
                            top: 0,
                            left: 0,
                            color: '#000000',
                        },
                        seconds: {
                            top: 0,
                            left: 0,
                            color: '#000000',
                        },
                    },
                    mobile: {
                        isEnabled: false,
                        fontFamily: 'Arial',
                        fontSize: 12,
                        hours: {
                            top: 0,
                            left: 0,
                            color: '#000000',
                        },
                        minutes: {
                            top: 0,
                            left: 0,
                            color: '#000000',
                        },
                        seconds: {
                            top: 0,
                            left: 0,
                            color: '#000000',
                        },
                    },
                    endTime: null,
                },
                assetType: 'banner',
                isCustomUrl: null,
                customUrl: '/product-page/7112',
                isOpenedInNewTab: null,
                close: null,
            },
            {
                url: {
                    desktop: '//static2-ppp.portalcdn.com/hd/mkt/prod/cp/as/desktop_165936795379007.jpg',
                    tablet: '//static2-ppp.portalcdn.com/hd/mkt/prod/cp/as/tablet_165936795489496.jpg',
                    mobile: '//static2-ppp.portalcdn.com/hd/mkt/prod/cp/as/mobile_164139563086271.jpg',
                },
                productPageId: 652,
                title: 'Downloads',
                bundleId: 4912,
                timer: {
                    desktop: {
                        isEnabled: false,
                        fontFamily: 'Arial',
                        fontSize: 12,
                        hours: {
                            top: 0,
                            left: 0,
                            color: '#000000',
                        },
                        minutes: {
                            top: 0,
                            left: 0,
                            color: '#000000',
                        },
                        seconds: {
                            top: 0,
                            left: 0,
                            color: '#000000',
                        },
                    },
                    tablet: {
                        isEnabled: false,
                        fontFamily: 'Arial',
                        fontSize: 12,
                        hours: {
                            top: 0,
                            left: 0,
                            color: '#000000',
                        },
                        minutes: {
                            top: 0,
                            left: 0,
                            color: '#000000',
                        },
                        seconds: {
                            top: 0,
                            left: 0,
                            color: '#000000',
                        },
                    },
                    mobile: {
                        isEnabled: false,
                        fontFamily: 'Arial',
                        fontSize: 12,
                        hours: {
                            top: 0,
                            left: 0,
                            color: '#000000',
                        },
                        minutes: {
                            top: 0,
                            left: 0,
                            color: '#000000',
                        },
                        seconds: {
                            top: 0,
                            left: 0,
                            color: '#000000',
                        },
                    },
                    endTime: null,
                },
                assetType: 'banner',
                isCustomUrl: null,
                customUrl: '/product-page/4912',
                isOpenedInNewTab: null,
                close: null,
            },
            {
                url: {
                    desktop: '//static2-ppp.portalcdn.com/hd/mkt/prod/cp/as/desktop_166844305126284.jpg',
                    tablet: '//static2-ppp.portalcdn.com/hd/mkt/prod/cp/as/tablet_166844305127249.jpg',
                    mobile: '//static2-ppp.portalcdn.com/hd/mkt/prod/cp/as/mobile_166844305163051.jpg',
                },
                productPageId: 462,
                title: 'Mile High',
                bundleId: 542,
                timer: {
                    desktop: {
                        isEnabled: false,
                        fontFamily: 'Arial',
                        fontSize: 12,
                        hours: {
                            top: 0,
                            left: 0,
                            color: '#000000',
                        },
                        minutes: {
                            top: 0,
                            left: 0,
                            color: '#000000',
                        },
                        seconds: {
                            top: 0,
                            left: 0,
                            color: '#000000',
                        },
                    },
                    tablet: {
                        isEnabled: false,
                        fontFamily: 'Arial',
                        fontSize: 12,
                        hours: {
                            top: 0,
                            left: 0,
                            color: '#000000',
                        },
                        minutes: {
                            top: 0,
                            left: 0,
                            color: '#000000',
                        },
                        seconds: {
                            top: 0,
                            left: 0,
                            color: '#000000',
                        },
                    },
                    mobile: {
                        isEnabled: false,
                        fontFamily: 'Arial',
                        fontSize: 12,
                        hours: {
                            top: 0,
                            left: 0,
                            color: '#000000',
                        },
                        minutes: {
                            top: 0,
                            left: 0,
                            color: '#000000',
                        },
                        seconds: {
                            top: 0,
                            left: 0,
                            color: '#000000',
                        },
                    },
                    endTime: null,
                },
                assetType: 'banner',
                isCustomUrl: null,
                customUrl: '/product-page/542',
                isOpenedInNewTab: null,
                close: null,
            },
            {
                url: {
                    desktop: '//static2-ppp.portalcdn.com/hd/mkt/prod/cp/as/desktop_166844305165682.jpg',
                    tablet: '//static2-ppp.portalcdn.com/hd/mkt/prod/cp/as/tablet_16684430517029.jpg',
                    mobile: '//static2-ppp.portalcdn.com/hd/mkt/prod/cp/as/mobile_166844305125881.jpg',
                },
                productPageId: 482,
                title: 'Property Sex',
                bundleId: 1432,
                timer: {
                    desktop: {
                        isEnabled: false,
                        fontFamily: 'Arial',
                        fontSize: 12,
                        hours: {
                            top: 0,
                            left: 0,
                            color: '#000000',
                        },
                        minutes: {
                            top: 0,
                            left: 0,
                            color: '#000000',
                        },
                        seconds: {
                            top: 0,
                            left: 0,
                            color: '#000000',
                        },
                    },
                    tablet: {
                        isEnabled: false,
                        fontFamily: 'Arial',
                        fontSize: 12,
                        hours: {
                            top: 0,
                            left: 0,
                            color: '#000000',
                        },
                        minutes: {
                            top: 0,
                            left: 0,
                            color: '#000000',
                        },
                        seconds: {
                            top: 0,
                            left: 0,
                            color: '#000000',
                        },
                    },
                    mobile: {
                        isEnabled: false,
                        fontFamily: 'Arial',
                        fontSize: 12,
                        hours: {
                            top: 0,
                            left: 0,
                            color: '#000000',
                        },
                        minutes: {
                            top: 0,
                            left: 0,
                            color: '#000000',
                        },
                        seconds: {
                            top: 0,
                            left: 0,
                            color: '#000000',
                        },
                    },
                    endTime: null,
                },
                assetType: 'banner',
                isCustomUrl: null,
                customUrl: '/product-page/1432',
                isOpenedInNewTab: null,
                close: null,
            },
        ],
    },
    marketplaceProfile: {
        memberId: '6fea72cf-bda9-4267-ba9d-a155d254630b',
        email: 'P1QA_11_bkzqpc@engpaysites.mindgeek.com',
        isDownloadBundlePurchased: false,
        currentSubscription: {
            subscriptionId: 'caa4d9c1-7a2b-4aaa-90be-1721d52c9644',
            creationDate: '2023-02-27T15:05:35+0000',
            entrySiteId: '8e34c94e-135f-4acb-9141-58b3a6e56c74',
            isBanned: false,
            affiliateTrackingCode: 'eyJhIjo5NDk2LCJjIjo1NjU4NTQyNSwibiI6MjAsInMiOjMxOCwiZSI6OTI0OCwicCI6M30=',
        },
        addons: [
            {
                addonId: 152,
                bundleIds: [
                    172,
                ],
                title: 'Reality Kings',
                thumb: '//static2-ppp.portalcdn.com/hd/mkt/prod/ad/th/1581453443.jpg',
                addonType: 'content',
                addonSubType: 'group_scenes',
                contentGroup: 195,
                contentGroupIds: [
                    195,
                ],
                featureName: '',
                supportLevel: null,
                subscriptionId: null,
                autologin: false,
                tokenUrl: null,
            },
        ],
        bundles: [
            {
                bundleId: 172,
                purchaseId: 'dbdd47d4-639c-4fb5-ac56-a37ac4e0bbf2',
                itemId: '8bef8632-7e0e-427f-8f16-fd1b11c69448',
                thumb: '//static2-ppp.portalcdn.com/hd/mkt/prod/bd/th/166550491693047.jpeg',
                initialDays: 30,
                initialAmount: {
                    beforeTaxes: 29.99,
                    taxes: 1.94,
                    afterTaxes: 31.93,
                },
                rebillDays: 30,
                rebillAmount: {
                    beforeTaxes: 29.99,
                    taxes: 1.94,
                    afterTaxes: 31.93,
                },
                currency: 'USD',
                transactionStatus: 'success',
                requireActiveContent: false,
                creationDate: '2023-02-27T15:05:35+0000',
                expirationDate: '2023-03-29T15:05:33+0000',
                isTrial: false,
                isActive: true,
                isExpired: false,
                isNsfOnPurchase: false,
                isUnlimited: false,
                isFirst: true,
                isDisabled: false,
                isGracePeriod: false,
                isMigrated: false,
                isFullStreamingAccess: false,
                freeSceneIds: null,
                addonIds: [
                    152,
                ],
            },
        ],
        libraries: [
            {
                title: 'Brazzers',
                bundleId: 182,
                contentGroupId: 5,
                currency: 'USD',
                currencySymbol: '$',
                prices: {
                    cc: [
                        {
                            unlockPricePerScene: 2,
                            unlockText: 'Billed in one payment of $amount',
                            currency: 'USD',
                            currencySymbol: '$',
                        },
                    ],
                },
                thumb: '/mkt/prod/bd/th/16179074445571.jpg',
            },
            {
                title: 'True Amateurs',
                bundleId: 372,
                contentGroupId: 27,
                currency: 'USD',
                currencySymbol: '$',
                prices: {
                    cc: [
                        {
                            unlockPricePerScene: 2,
                            unlockText: 'Billed in one payment of $amount',
                            currency: 'USD',
                            currencySymbol: '$',
                        },
                    ],
                    banktransfer: [
                        {
                            unlockPricePerScene: 1,
                            unlockText: 'Billed in one payment of $amount',
                            currency: 'USD',
                            currencySymbol: '$',
                        },
                    ],
                },
                thumb: '/mkt/prod/bd/th/161797724186732.jpg',
            },
            {
                title: 'Trans Angels',
                bundleId: 1682,
                contentGroupId: 23,
                currency: 'USD',
                currencySymbol: '$',
                prices: {
                    cc: [
                        {
                            unlockPricePerScene: 2,
                            unlockText: 'Billed in one payment of $amount',
                            currency: 'USD',
                            currencySymbol: '$',
                        },
                    ],
                    banktransfer: [
                        {
                            unlockPricePerScene: 1,
                            unlockText: 'Billed in one payment of $amount',
                            currency: 'USD',
                            currencySymbol: '$',
                        },
                    ],
                },
                thumb: '/mkt/prod/bd/th/161797734897153.jpg',
            },
            {
                title: 'Digital Playground',
                bundleId: 152,
                contentGroupId: 9,
                currency: 'USD',
                currencySymbol: '$',
                prices: {
                    cc: [
                        {
                            unlockPricePerScene: 2,
                            unlockText: 'Billed in one payment of $amount',
                            currency: 'USD',
                            currencySymbol: '$',
                        },
                    ],
                    banktransfer: [
                        {
                            unlockPricePerScene: 1,
                            unlockText: 'Billed in one payment of $amount',
                            currency: 'USD',
                            currencySymbol: '$',
                        },
                    ],
                },
                thumb: '/mkt/prod/bd/th/161549077441651.jpg',
            },
            {
                title: 'Babes',
                bundleId: 132,
                contentGroupId: 3,
                currency: 'USD',
                currencySymbol: '$',
                prices: {
                    cc: [
                        {
                            unlockPricePerScene: 2,
                            unlockText: 'Billed in one payment of $amount',
                            currency: 'USD',
                            currencySymbol: '$',
                        },
                    ],
                    banktransfer: [
                        {
                            unlockPricePerScene: 1,
                            unlockText: 'Billed in one payment of $amount',
                            currency: 'USD',
                            currencySymbol: '$',
                        },
                    ],
                },
                thumb: '/mkt/prod/bd/th/161532200751020.jpg',
            },
            {
                title: 'Twistys',
                bundleId: 162,
                contentGroupId: 29,
                currency: 'USD',
                currencySymbol: '$',
                prices: {
                    cc: [
                        {
                            unlockPricePerScene: 2,
                            unlockText: 'Billed in one payment of $amount',
                            currency: 'USD',
                            currencySymbol: '$',
                        },
                    ],
                    banktransfer: [
                        {
                            unlockPricePerScene: 1,
                            unlockText: 'Billed in one payment of $amount',
                            currency: 'USD',
                            currencySymbol: '$',
                        },
                    ],
                },
                thumb: '/mkt/prod/bd/th/16153215613676.jpg',
            },
            {
                title: 'Mofos',
                bundleId: 142,
                contentGroupId: 15,
                currency: 'USD',
                currencySymbol: '$',
                prices: {
                    cc: [
                        {
                            unlockPricePerScene: 2,
                            unlockText: 'Billed in one payment of $amount',
                            currency: 'USD',
                            currencySymbol: '$',
                        },
                    ],
                    banktransfer: [
                        {
                            unlockPricePerScene: 1,
                            unlockText: 'Billed in one payment of $amount',
                            currency: 'USD',
                            currencySymbol: '$',
                        },
                    ],
                },
                thumb: '/mkt/prod/bd/th/161532118911455.jpg',
            },
            {
                title: 'Hentai Pros',
                bundleId: 382,
                contentGroupId: 13,
                currency: 'USD',
                currencySymbol: '$',
                prices: {
                    cc: [
                        {
                            unlockPricePerScene: 2,
                            unlockText: 'Billed in one payment of $amount',
                            currency: 'USD',
                            currencySymbol: '$',
                        },
                    ],
                    banktransfer: [
                        {
                            unlockPricePerScene: 1,
                            unlockText: 'Billed in one payment of $amount',
                            currency: 'USD',
                            currencySymbol: '$',
                        },
                    ],
                },
                thumb: '/mkt/prod/bd/th/161530990139551.jpg',
            },
            {
                title: 'Erito',
                bundleId: 392,
                contentGroupId: 11,
                currency: 'USD',
                currencySymbol: '$',
                prices: {
                    cc: [
                        {
                            unlockPricePerScene: 2,
                            unlockText: 'Billed in one payment of $amount',
                            currency: 'USD',
                            currencySymbol: '$',
                        },
                    ],
                    banktransfer: [
                        {
                            unlockPricePerScene: 1,
                            unlockText: 'Billed in one payment of $amount',
                            currency: 'USD',
                            currencySymbol: '$',
                        },
                    ],
                },
                thumb: '/mkt/prod/bd/th/161797738837514.jpg',
            },
            {
                title: 'Premium',
                bundleId: 52,
                contentGroupId: 33,
                currency: 'USD',
                currencySymbol: '$',
                prices: {
                    cc: [
                        {
                            unlockPricePerScene: 2,
                            unlockText: 'Billed in one payment of $amount',
                            currency: 'USD',
                            currencySymbol: '$',
                        },
                    ],
                    banktransfer: [
                        {
                            unlockPricePerScene: 1,
                            unlockText: 'Billed in one payment of $amount',
                            currency: 'USD',
                            currencySymbol: '$',
                        },
                    ],
                },
                thumb: '/mkt/prod/bd/th/163233241427039.jpg',
            },
            {
                title: 'WhyNotBi',
                bundleId: 1562,
                contentGroupId: 133,
                currency: 'USD',
                currencySymbol: '$',
                prices: {
                    cc: [
                        {
                            unlockPricePerScene: 2,
                            unlockText: 'Billed in one payment of $amount',
                            currency: 'USD',
                            currencySymbol: '$',
                        },
                    ],
                    banktransfer: [
                        {
                            unlockPricePerScene: 1,
                            unlockText: 'Billed in one payment of $amount',
                            currency: 'USD',
                            currencySymbol: '$',
                        },
                    ],
                },
                thumb: '/mkt/prod/bd/th/163242481789961.jpg',
            },
        ],
        paymentOptions: {
            catalog: [
                {
                    paymentType: 'cc',
                    paymentMethod: 'cc',
                    priority: 1,
                },
            ],
            library: [
                {
                    paymentType: 'cc',
                    paymentMethod: 'cc',
                    priority: 1,
                },
            ],
        },
    },
    libraries: [],
    lastFour: null,
};

export default mockUser;
