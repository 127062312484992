import React, { useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Button from '../Button';
import ButtonEntry from './ButtonEntry';

import CampaignBundles from './CampaignBundles';
import { NavigationContext } from '../../context/NavigationContext';
import {
    BUNDLES_KEYS,
    CAMPAIGN_BUNDLES,
    filterByTags,
    updateContentValues,
} from './processingBundles';

const defaultStyles = {
    lineHeight: 1,
    display: 'flex',
    position: 'relative',
    textDecoration: 'none',
};

const Wrapper = styled.div(({ styles }) => styles);

const DynamicGroup = props => {
    const {
        attributes,
        structureType,
        isChildButton,
        attributes: {
            dataType,
            bundelsSort = 'id',
            marketplaceTags = '',
        } = {},
        styles: {
            mainButton,
            subButton,
        } = {},
        ...rest
    } = props;

    const { dynamicContent = {}, user } = useContext(NavigationContext);
    const content = dynamicContent[dataType];

    if (!content) {
        return null;
    }

    const { linkQueryKey, linkType } = BUNDLES_KEYS[dataType];
    const { collection = [] } = updateContentValues({
        bundelsSort,
        content,
        dataType,
        linkQueryKey,
        linkType,
        user,
    });

    if (dataType === CAMPAIGN_BUNDLES) {
        const tmpContent = {
            collection: filterByTags(collection, marketplaceTags),
            linkQueryKey,
            linkType,
        };

        return (
            <CampaignBundles
                content={tmpContent}
                {...props}
            />
        );
    }

    return collection && collection.length === 0 ? null : (
        <Wrapper
            {...rest}
            styles={{ ...defaultStyles, ...(isChildButton && { flexDirection: 'column' }) }}
        >
            {isChildButton && structureType === 'mobile' ? (
                <>
                    <Button
                        attributes={attributes}
                        structureType={structureType}
                        styles={mainButton}
                        isChildButton={isChildButton}
                        {...rest}
                    />
                    {collection.map(item => (
                        <ButtonEntry
                            key={`dg_${item.id}`}
                            id={item.id}
                            title={item.title}
                            linkType={item.linkType || linkType}
                            linkTypeQuery={{ [linkQueryKey]: item.id }}
                            structureType={structureType}
                            styles={subButton}
                        />
                    ))}
                </>
            ) : (
                <Button
                    attributes={attributes}
                    structureType={structureType}
                    styles={mainButton}
                    isChildButton={isChildButton}
                    {...rest}
                >
                    {collection.map(item => (
                        <ButtonEntry
                            key={`dg_${item.id}`}
                            id={item.id}
                            title={item.title}
                            linkType={item.linkType || linkType}
                            linkTypeQuery={{ [linkQueryKey]: item.id }}
                            structureType={structureType}
                            styles={subButton}
                        />
                    ))}
                </Button>
            )}
        </Wrapper>
    );
};

DynamicGroup.propTypes = {
    isChildButton: PropTypes.bool,
    styles: PropTypes.object,
    structureType: PropTypes.string,
    attributes: PropTypes.object,
};

export default DynamicGroup;
