import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Column from './Column';

class SuperColumn extends Component {
    static contextTypes = {
        spanContext: PropTypes.array,
    };

    static childContextTypes = {
        spanContext: PropTypes.array,
    };

    getChildContext() {
        // TODO extract this etc into variables to make this readable,
        // also change the name of spanContext
        const addSpanContext = this.context.spanContext
            ? this.context.spanContext.concat([this.props.span])
            : [this.props.span];
        return { spanContext: addSpanContext };
    }

    render() {
        return <Column {...this.props} />;
    }
}

SuperColumn.displayName = 'SuperColumn';

export default SuperColumn;
