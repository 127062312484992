import React from 'react';
import PropTypes from 'prop-types';

const AccordionContainer = ({ children, isOpen, styles }) => isOpen && (
    <div className="dynamicGroupsCollapse" style={styles}>
        {children}
    </div>
);

AccordionContainer.propTypes = {
    children: PropTypes.node,
    isOpen: PropTypes.bool,
    styles: PropTypes.object,
};

export default AccordionContainer;
