import PropTypes from 'prop-types';
import { isFunction } from 'lodash';

const videoLoader = ({
    video = {},
    onloadedmetadata,
    onerror,
} = {}) => {
    const loader = document && document.createElement('video');

    if (loader) {
        loader.onloadedmetadata = (metaData) => {
            if (isFunction(onloadedmetadata)) {
                onloadedmetadata(metaData);
            }
        };

        loader.onerror = () => {
            if (isFunction(onerror)) {
                onerror();
            }
        };

        loader.src = video.src;
    }
};

videoLoader.propTypes = {
    video: PropTypes.shape({
        src: PropTypes.string.isRequired,
    }),
    onloadedmetadata: PropTypes.func,
    onerror: PropTypes.func,
};

export default videoLoader;
