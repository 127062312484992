import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { PortalWithState } from 'react-portal';

const SearchDrawer = props => {
    const { onClose, children } = props;
    const portalRef = useRef();

    return (
        <PortalWithState defaultOpen closeOnEsc={false} ref={portalRef} onClose={onClose}>
            {({ portal }) => portal(<>{children}</>)}
        </PortalWithState>
    );
};

SearchDrawer.propTypes = {
    onClose: PropTypes.func,
    children: PropTypes.node,
};

export default SearchDrawer;
