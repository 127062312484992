const RELEASE_TYPE_BTS = 'bts';
const RELEASE_TYPE_GALLERY = 'gallery';
const RELEASE_TYPE_MOVIE = 'movie';
const RELEASE_TYPE_SCENE = 'scene';
const RELEASE_TYPE_SERIE = 'serie';
const RELEASE_TYPE_TRAILER = 'trailer';

const RELEASE_TYPE_ROUTE_MAP = {
    [RELEASE_TYPE_BTS]: 'BTS_PROFILE',
    [RELEASE_TYPE_GALLERY]: 'GALLERY',
    [RELEASE_TYPE_MOVIE]: 'MOVIE_PROFILE',
    [RELEASE_TYPE_SCENE]: 'PLAYER',
    [RELEASE_TYPE_SERIE]: 'SERIES_PROFILE',
    [RELEASE_TYPE_TRAILER]: 'PLAYER',
};

const getRouteFromType = releaseType => RELEASE_TYPE_ROUTE_MAP[releaseType];

export default getRouteFromType;
