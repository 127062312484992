import styled from 'styled-components';
import getStyles from '../../util/getStyles';
import {
    InitComponent,
    MenuIconButton,
    MenuIconCloseButton,
    SearchField,
    SearchFieldOverlay,
    SelectedType,
    TypeResults,
} from './styledComponents';

const defaultStyles = {
    margin: '8px 0px',
    fontSize: '16px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    color: 'black',
    borderRadius: '4px',
};

export const compileStyledComponents = styles => {
    const searchBoxStyles = {
        searchButton: { ...getStyles(styles.searchButton) },
        searchInput: { ...getStyles(styles.searchInput) },
        typeSelector: { ...getStyles(styles.typeSelector) },
        searchOverlay: { ...getStyles(styles.searchOverlay) },
    };

    const StyledComponent = styled(InitComponent)({
        ...defaultStyles,

        [MenuIconButton]: {
            ...searchBoxStyles.searchButton.rootStyles,
        },
        [MenuIconCloseButton]: {
            ...searchBoxStyles.searchButton.rootStyles,
        },
        [SearchField]: {
            ...searchBoxStyles.searchInput.rootStyles,
        },
        [SelectedType]: {
            ...searchBoxStyles.typeSelector.rootStyles,
        },
        [TypeResults]: {
            ...searchBoxStyles.typeSelector.childrenStyles.dropdown,
        },
        [SearchFieldOverlay]: {
            ...searchBoxStyles.searchOverlay.rootStyles,
        },
    });

    return {
        searchBoxStyles,
        StyledComponent,
    };
};

export const extractSearchWord = value => value.replace(/^\s+|/g, '');

export const getSelectedTypes = types => {
    let result = [];
    if (types && types.length > 0) {
        result = types.split(';');
    }
    return result;
};
