// import { defaultGrid, DEFAULT_ITEMS_PER_ROW } from '@one/theme-default';

// ----------------------------------------------------------------------------
// WARNING: These constants were copied from @one/theme-default
// due to a circular package dependency that prevented yarn from
// installing the project.
// ----------------------------------------------------------------------------

// type of items per row that the grid can use
const ACTORS_PER_ROW = 'actorsPerRow'; // !!! keep the same name as in the instance (i.e. theme.grid.actorsPerRow)
const RELEASES_PER_ROW = 'releasesPerRow'; // !!! keep the same name as in the instance (i.e. theme.grid.releasesPerRow)
const DEFAULT_ITEMS_PER_ROW = 'defaultItemsPerRow';

// Default grid by items per row
const defaultGrid = {
    // default values for actorsPerRow
    // Can be overwritten if theme.grid.actorsPerRow is defined in the instance
    [ACTORS_PER_ROW]: {
        base: 1,
        small: 2,
        medium: 3,
        'large-up': 4,
        'extra-large': 4,
        huge: 6,
    },
    // default values for releasesPerRow
    // Can be overwritten if theme.grid.releasesPerRow is defined in the instance
    [RELEASES_PER_ROW]: {
        base: 1,
        small: 2,
        medium: 3,
        'large-up': 4,
        'extra-large': 4,
        huge: 6,
    },
    // Default values for any other items
    // Cannot be overwritten
    [DEFAULT_ITEMS_PER_ROW]: {
        base: 1,
        small: 2,
        medium: 3,
        'large-up': 3,
        'extra-large': 3,
        huge: 4,
    },
};

/**
 * Returns the list of span sizes based of the type of items
 * For example, if the type of items is a list of actor cards,
 * We can call getSpanSizes(theme, ACTORS_PER_ROW);
 * See themes/default/constants.js for the list of types available
 * @param  {Object} [theme={}]                   The theme of the instance
 * @param  {String} [type=DEFAULT_ITEMS_PER_ROW] The type of items per row (optional).
 * By default, it is set to DEFAULT_ITEMS_PER_ROW
 * @return {Object}                              the list of sizes
 */
export default function getSpanSizes(theme = {}, type = DEFAULT_ITEMS_PER_ROW, overrideCols = {}) {
    let { grid = {} } = theme;
    const { size: gridSize = 12 } = grid;

    if (overrideCols && overrideCols.enable) {
        grid = {
            ...grid,
            [ACTORS_PER_ROW]: {
                ...defaultGrid[ACTORS_PER_ROW],
                ...grid[ACTORS_PER_ROW],
                ...overrideCols,
            },
            [RELEASES_PER_ROW]: {
                ...defaultGrid[RELEASES_PER_ROW],
                ...grid[RELEASES_PER_ROW],
                ...overrideCols,
            },
            [DEFAULT_ITEMS_PER_ROW]: {
                ...defaultGrid[DEFAULT_ITEMS_PER_ROW],
                ...grid[DEFAULT_ITEMS_PER_ROW],
                ...overrideCols,
            },
        };
    }

    // If the type is not defined in the instance, use the default (hardcoded) grid
    // If the type doesn't exist in the default grid, return an empty object
    // (to avoid throwing undefined errors)
    const { small, medium, 'large-up': large, 'extra-large': extraLarge, huge } =
        grid[type] || defaultGrid[type] || {};

    return {
        'base-up': gridSize,
        'small-up': gridSize / small || 1,
        'medium-up': gridSize / medium || 1,
        'large-up': gridSize / large || 1,
        'extra-large-up': gridSize / extraLarge || 1,
        'huge-up': gridSize / huge || 1,
    };
}
