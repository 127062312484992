import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import {
    BigSpinner,
    ResultContainer,
    ResultLink,
    ResultsHeader,
    SearchResults,
    TypeList,
    TypeItem,
} from './styledComponents';
import getSearchResultLink from './getSearchResultLink';

const highlightQuerySearch = (text = '', higlight = '') => {
    const parts = text.split(new RegExp(`(${higlight})`, 'i'));
    return parts.map(part => (
        <>
            {part.toLowerCase() === higlight.toLowerCase() ? (
                <span className="search-query">{part}</span>
            ) : (
                part
            )}
        </>
    ));
};

const SearchResult = ({
    handleOnchangeType,
    handleSearchResultClick,
    isMobile,
    isShow,
    isLoading,
    overrideTitles = {},
    searchValue,
    searchResult = {},
    searchTypes = [],
    selectedType = '',
    styles,
    source,
}) => {
    const {
        actors = [],
        scenes = [],
        suggestions = [],
    } = searchResult;

    const typeListStyles = {
        borderColor: get(styles, 'typeSelector.rootStyles.borderColor', 'yellow'),
        color: get(styles, 'typeSelector.rootStyles.color', 'white'),
        backgroundColor: get(styles, 'typeSelector.rootStyles.backgroundColor', 'black'),
        activeBorderColor: get(styles, 'typeSelector.childrenStyles.colorIsActive.borderColor', 'yellow'),
        activeColor: get(styles, 'typeSelector.childrenStyles.colorIsActive.color', 'black'),
        activeBackgroundColor: get(styles, 'typeSelector.childrenStyles.colorIsActive.backgroundColor', 'yellow'),
        borderWidth: get(styles, 'typeSelector.rootStyles.borderWidth', '1px'),
    };

    const spinnerSize = get(styles, 'spinners.rootStyles.searchResultSpinnerSize', '16px');
    const spinnerColor = get(styles, 'spinners.rootStyles.searchResultSpinnerColor', 'yellow');
    const isUseSearchResultSpinner = get(styles, 'spinners.rootStyles.isUseSearchResultSpinner', false);
    const isShowSpinner = isLoading && isUseSearchResultSpinner;

    const resultContainerStyles = {
        textColor: get(styles, 'searchResult.rootStyles.color', 'grey'),
        hoverTextColor: get(styles, 'searchResult.rootStyles.&:hover.color', 'grey'),
        backgroundColor: get(styles, 'searchResult.rootStyles.backgroundColor', 'black'),
        hoverBackgroundColor: get(styles, 'searchResult.rootStyles.&:hover.backgroundColor', 'yellow'),
        highlightColor: get(styles, 'searchResult.rootStyles.highlightColor', 'white'),
        hoverHighlightColor: get(styles, 'searchResult.rootStyles.&:hover.highlightColor', 'white'),
    };

    const titles = {
        all: overrideTitles.isOverrideAllTabTitle ? overrideTitles.titleAllTab : 'All',
        actor: overrideTitles.isOverrideActorsTabTitle ? overrideTitles.titleActorsTab : 'Actors',
        scene: overrideTitles.isOverrideScenesTabTitle ? overrideTitles.titleScenesTab : 'Scenes',
        categories: overrideTitles.isOverrideCategoriesTabTitle ? overrideTitles.titleCategoriesTab : 'Categories',
        suggestions: overrideTitles.isOverrideSuggestionsTabTitle ? overrideTitles.titleSuggestionsTab : 'Suggestions',
    };

    return isShow && (
        <SearchResults isMobile={isMobile}>
            {searchTypes.length > 1 && (
                <TypeList>
                    {searchTypes.map(item => (
                        <TypeItem
                            key={item}
                            isActive={selectedType === item}
                            onClick={handleOnchangeType(item)}
                            styles={typeListStyles}
                        >
                            {String(titles[item]).toUpperCase()}
                        </TypeItem>
                    ))}
                </TypeList>
            )}
            {isShowSpinner && <BigSpinner color={spinnerColor} spinnerSize={spinnerSize} />}
            {scenes.length > 0 && (
                <div>
                    <ResultsHeader>{titles.scene}</ResultsHeader>
                    {scenes.map(release => (
                        <ResultContainer key={release.id} styles={resultContainerStyles}>
                            <ResultLink
                                title={release.title || release.name}
                                onClick={handleSearchResultClick}
                                to={getSearchResultLink({
                                    searchType: selectedType,
                                    itemType: 'scenes',
                                    item: release,
                                    source,
                                })}
                            >
                                {highlightQuerySearch(release.title || release.name, searchValue)}
                            </ResultLink>
                        </ResultContainer>
                    ))}
                </div>
            )}
            {actors.length > 0 && (
                <div>
                    <ResultsHeader>{titles.actor}</ResultsHeader>
                    {actors.map(actor => (
                        <ResultContainer key={actor.id} styles={resultContainerStyles}>
                            <ResultLink
                                title={actor.name}
                                onClick={handleSearchResultClick}
                                to={getSearchResultLink({
                                    searchType: selectedType,
                                    itemType: 'actors',
                                    item: actor,
                                    source,
                                })}
                            >
                                {highlightQuerySearch(actor.name, searchValue)}
                            </ResultLink>
                        </ResultContainer>
                    ))}
                </div>
            )}
            {suggestions.length > 0 && (
                <div>
                    <ResultsHeader>{titles.suggestions}</ResultsHeader>
                    {suggestions.map(suggestion => (
                        <ResultContainer key={suggestion.id} styles={resultContainerStyles}>
                            <ResultLink
                                title={suggestion.name}
                                onClick={handleSearchResultClick}
                                to={getSearchResultLink({
                                    searchType: selectedType,
                                    itemType: 'suggestions',
                                    item: suggestion,
                                    source,
                                })}
                            >
                                {highlightQuerySearch(suggestion.name, searchValue)}
                            </ResultLink>
                        </ResultContainer>
                    ))}
                </div>
            )}
        </SearchResults>
    );
};

SearchResult.propTypes = {
    handleOnchangeType: PropTypes.func,
    handleSearchResultClick: PropTypes.func,
    isMobile: PropTypes.bool,
    isShow: PropTypes.bool,
    isLoading: PropTypes.bool,
    overrideTitles: PropTypes.object,
    searchTypes: PropTypes.array,
    searchValue: PropTypes.string,
    selectedType: PropTypes.string,
    searchResult: PropTypes.object,
    styles: PropTypes.object,
    source: PropTypes.string,
};

export default SearchResult;
