/* eslint-disable no-console */
import axios from 'axios';
import getCookie from '../util/getCookie';

export const getActors = ({
    navigationApiUrl,
    blockId,
    blockName,
    pageType,
    source,
    query,
    username,
    limit,
    offset,
    headers,
    memberUuid,
}) => {
    const url = `${navigationApiUrl}/dd/v1/actors`;
    const params = {
        blockId,
        blockName,
        pageType,
        source,
        query,
        username,
        limit,
        offset,
        memberUuid,
    };

    return axios
        .get(url, { headers, params })
        .then(res => res.data)
        .catch(err => {
            console.log(err);
            return [];
        });
};

export const getScenes = ({
    navigationApiUrl,
    blockId,
    blockName,
    pageType,
    source,
    query,
    username,
    limit,
    offset,
    headers,
    memberUuid,
}) => {
    const url = `${navigationApiUrl}/dd/v1/videos`;
    const params = {
        blockId,
        blockName,
        pageType,
        source,
        query,
        username,
        limit,
        offset,
        memberUuid,
    };

    return axios
        .get(url, { headers, params })
        .then(res => res.data)
        .catch(err => {
            console.log(err);
            return [];
        });
};

export const getSuggestions = ({ navigationApiUrl, source, query, limit, offset, headers }) => {
    const url = `${navigationApiUrl}/dd/v1/suggestions`;
    const params = { source, query, limit, offset };

    return axios
        .get(url, { headers, params })
        .then(res => res.data)
        .catch(err => {
            console.log(err);
            return [];
        });
};

export const getSearchData = ({
    blockId,
    blockName,
    pageType,
    navigationApiUrl,
    limit,
    offset,
    query,
    source,
    referrerHeaders,
    username,
    memberUuid,
}) => {
    const { instance_token, access_token_ma } = getCookie(); // eslint-disable-line camelcase

    const headers = {
        Instance: instance_token,
        Authorization: access_token_ma,
        ...referrerHeaders,
    };

    return Promise.all([
        getActors({
            navigationApiUrl,
            blockId,
            blockName,
            pageType,
            source,
            query,
            username,
            limit,
            offset,
            headers,
            memberUuid,
        }),
        getScenes({
            navigationApiUrl,
            blockId,
            blockName,
            pageType,
            source,
            query,
            username,
            limit,
            offset,
            headers,
            memberUuid,
        }),
        getSuggestions({ navigationApiUrl, source, query, headers }),
    ]).then(res => ({
        actors: res[0].result,
        actorsCategories: res[0].meta?.tags,
        scenes: res[1].result,
        scenesCategories: res[1].meta?.tags,
        suggestions: res[2].searchSuggestion,
    }));
};
