/* eslint-disable react/prop-types */
import React from 'react';
import get from 'lodash/get';

import {
    Container,
    MenuIcon,
    MenuIconCloseButton,
    SearchField,
    SearchFieldContainer,
    SearchFieldOverlay,
    SearchIcon,
    SmallSpinner,
} from './styledComponents';
import SearchResult from './SearchResult';

const SearchContent = ({
    handleOnchangeType,
    handleOnKeydown,
    handleOnSearchChange,
    handleSearchResultClick,
    handleSearchToggle,
    isLoading,
    inMobileDrawer,
    isMobile,
    isSearchExpanded,
    overrideTitles,
    searchBoxStyles,
    searchFieldRef,
    searchValue,
    selectedType,
    searchTypes,
    searchResult,
    source,
}) => {
    const spinnerSize = get(searchBoxStyles, 'spinners.rootStyles.inputSpinnerSize', '4px');
    const spinnerColor = get(searchBoxStyles, 'spinners.rootStyles.inputSpinnerColor', 'yellow');
    const isUseInputSpinner = get(searchBoxStyles, 'spinners.rootStyles.isUseInputSpinner', false);
    const isShowSpinner = isLoading && isUseInputSpinner;

    return (
        <SearchFieldOverlay
            isExpanded={isSearchExpanded}
            ref={searchFieldRef}
            isMobile={isMobile}
            inMobileDrawer={inMobileDrawer}
            styles={searchBoxStyles.searchOverlay}
        >
            {isSearchExpanded && (
                <Container>
                    {isSearchExpanded && <SearchIcon icon="Search" />}
                    <SearchFieldContainer>
                        <SearchField
                            isExpanded={isSearchExpanded}
                            onChange={handleOnSearchChange}
                            onKeyPress={handleOnKeydown}
                            value={searchValue}
                            autoFocus
                        />
                        {isShowSpinner && (<SmallSpinner color={spinnerColor} spinnerSize={spinnerSize} />)}
                        <SearchResult
                            handleOnchangeType={handleOnchangeType}
                            handleSearchResultClick={handleSearchResultClick}
                            isMobile={isMobile}
                            isLoading={isLoading}
                            overrideTitles={overrideTitles}
                            isShow={isSearchExpanded && searchValue.length > 2}
                            searchValue={searchValue}
                            searchTypes={searchTypes}
                            searchResult={searchResult}
                            selectedType={selectedType}
                            styles={searchBoxStyles}
                            source={source}
                        />
                    </SearchFieldContainer>
                    <MenuIconCloseButton onClick={handleSearchToggle}>
                        {isSearchExpanded && <MenuIcon icon="X" />}
                    </MenuIconCloseButton>
                </Container>
            )}
        </SearchFieldOverlay>
    );
};

export default SearchContent;
