const logo = JSON.stringify({
    url: 'https://images-assets-ht.project1content.com/Brazzers/Tour/Tour/PC/5f1b1271a1e429.16105348.png',
    urls: {
        /* eslint-disable max-len */
        default: 'https://imageservice-assets-ht.project1content.com/m=bd/assets/Brazzers/Tour/Tour/PC/5f1b1271a1e429.16105348.jpg',
        png: 'https://imageservice-assets-ht.project1content.com/m=gd/assets/Brazzers/Tour/Tour/PC/5f1b1271a1e429.16105348.png',
        webp: 'https://imageservice-assets-ht.project1content.com/m=bZi-b/assets/Brazzers/Tour/Tour/PC/5f1b1271a1e429.16105348.webp',
        gif: 'https://imageservice-assets-ht.project1content.com/m=bZq/assets/Brazzers/Tour/Tour/PC/5f1b1271a1e429.16105348.gif',
        /* eslint-enable max-len */
    },
});

const mockTheme = {
    assets: {
        general: {
            logo,
        },
    },
    color: {
        background: '#000000',
        blockBgColor: '#ffffff',
        blockHeaderBar: '#d6d6d6',
        card: '#2857f7',
        cardTitleLinkColor: '#FBCA27',
        dropdownSubMenuBackground: '#363433',
        dropdownTextColor: '#ffffff',
        menuBg: '#000000',
        menuEnd: '#000000',
        menuStart: '#000000',
        primary: '#FBCA27',
        text: '#ffffff',
    },
    grid: {
        breakpoints: {
            base: 0,
            small: 200,
            medium: 800,
            large: 1000,
            'extra-large': 1300,
            huge: 1600,
        },
        containers: {
            small: '100%',
            medium: '100%',
            large: '100%',
            'extra-large': '100%',
            huge: '100%',
        },
    },
};

export default mockTheme;
