const dynamicContent = {
    USER_ADDONS: {
        collection: [
            { id: 372, title: 'True Amateurs' },
            { id: 1682, title: 'Trans Angels' },
            { id: 172, title: 'Reality Kings' },
            { id: 152, title: 'Digital Playground' },
            { id: 132, title: 'Babes' },
            { id: 162, title: 'Twistys' },
            { id: 142, title: 'Mofos' },
            { id: 382, title: 'Hentai Pros' },
            { id: 392, title: 'Erito' },
        ],
        linkQueryKey: 'library',
        linkType: 'EXPLORE_SCENES',
    },
    USER_LIBRARY: {
        collection: [
            { id: 162, title: 'Brazzers' },
        ],
        linkQueryKey: 'addon',
        linkType: 'EXPLORE_SCENES',
    },
};

export default dynamicContent;
