// type of items per row that the grid can use
export const ACTORS_PER_ROW = 'actorsPerRow'; // !!! keep the same name as in the instance (i.e. theme.grid.actorsPerRow)
export const RELEASES_PER_ROW = 'releasesPerRow'; // !!! keep the same name as in the instance (i.e. theme.grid.releasesPerRow)
export const DEFAULT_ITEMS_PER_ROW = 'defaultItemsPerRow';

// Default grid by items per row
export const defaultGrid = {
    // default values for actorsPerRow
    // Can be overwritten if theme.grid.actorsPerRow is defined in the instance
    [ACTORS_PER_ROW]: {
        base: 1,
        small: 2,
        medium: 3,
        'large-up': 4,
        'extra-large': 4,
        huge: 6,
    },
    // default values for releasesPerRow
    // Can be overwritten if theme.grid.releasesPerRow is defined in the instance
    [RELEASES_PER_ROW]: {
        base: 1,
        small: 2,
        medium: 3,
        'large-up': 4,
        'extra-large': 4,
        huge: 6,
    },
    // Default values for any other items
    // Cannot be overwritten
    [DEFAULT_ITEMS_PER_ROW]: {
        base: 1,
        small: 2,
        medium: 3,
        'large-up': 3,
        'extra-large': 3,
        huge: 4,
    },
};
