import { css } from 'styled-components';
import {
    mediaUp,
    mediaDown,
    mediaBetween,
    mediaExclude,
    mediaExcludeOnly,
    mediaOnly,
} from '@one/grid';

function boundMediaUp(name) {
    return (...args) => props =>
        mediaUp(name, props.theme.grid.breakpoints)`
            ${css(...args)}
        `;
}

function boundMediaDown(name) {
    return (...args) => props =>
        mediaDown(name, props.theme.grid.breakpoints)`
            ${css(...args)}
        `;
}

function boundMediaBetween(lower, upper) {
    return (...args) => props =>
        mediaBetween(lower, upper, props.theme.grid.breakpoints)`
            ${css(...args)}
        `;
}

function boundMediaExclude(lower, upper) {
    return (...args) => props =>
        mediaExclude(lower, upper, props.theme.grid.breakpoints)`
            ${css(...args)}
        `;
}

function boundMediaExcludeOnly(name) {
    return (...args) => props =>
        mediaExcludeOnly(name, props.theme.grid.breakpoints)`
            ${css(...args)}
        `;
}

function boundMediaOnly(name) {
    return (...args) => props =>
        mediaOnly(name, props.theme.grid.breakpoints)`
            ${css(...args)}
        `;
}

export const getBreakPoint = theme => {
    const width = window.innerWidth;
    const {
        grid: { breakpoints },
    } = theme;

    const sizeOrdered = ['huge', 'extra-large', 'large', 'medium', 'small'];
    const breakPointName = sizeOrdered.find(name => breakpoints[name] <= width) || 'base';

    return breakPointName;
};

export {
    boundMediaUp as mediaUp,
    boundMediaDown as mediaDown,
    boundMediaBetween as mediaBetween,
    boundMediaExclude as mediaExclude,
    boundMediaExcludeOnly as mediaExcludeOnly,
    boundMediaOnly as mediaOnly,
};

export const baseUp = boundMediaUp('base');
export const smallUp = boundMediaUp('small');
export const mediumUp = boundMediaUp('medium');
export const largeUp = boundMediaUp('large');
export const extraLargeUp = boundMediaUp('extra-large');
export const hugeUp = boundMediaUp('huge');

export const baseDown = boundMediaDown('base');
export const smallDown = boundMediaDown('small');
export const mediumDown = boundMediaDown('medium');
export const largeDown = boundMediaDown('large');
export const extraLargeDown = boundMediaDown('extra-large');
export const hugeDown = boundMediaDown('huge');

export const baseOnly = boundMediaOnly('base');
export const smallOnly = boundMediaOnly('small');
export const mediumOnly = boundMediaOnly('medium');
export const largeOnly = boundMediaOnly('large');
export const extraLargeOnly = boundMediaOnly('extra-large');
export const hugeOnly = boundMediaOnly('huge');

export function device(deviceName) {
    // TODO do something
    return deviceName;
}

export const mobile = device('mobile');
export const phone = device('phone');
export const tablet = device('tablet');
export const desktop = device('desktop');
