/**
 * Get the width of a span in percentage
 *
 * @param  {int}    cols How many columns to span
 * @param  {int}    size Size of the grid
 * @return {String}      Percentage width
 */
export default function getSpanWidth(cols, size) {
    const width = cols / size || 0;
    return `${Math.min(width * 100, 100)}%`;
}
