import { format, isFuture, isWithinRange } from 'date-fns';

const dateMapping = {
    thisweek: 7,
    thismonth: 30,
    thisyear: 365,
};

export function getRelativeDateRange(param) {
    const endDateTime = new Date();
    const startDateTime = new Date(endDateTime.getTime() - dateMapping[param] * 86400000);

    switch (param) {
        case 'upcoming':
            return `>${format(endDateTime, 'YYYY-MM-DD')}`;
        case 'any':
            return `<${format(endDateTime.setDate(endDateTime.getDate() + 1), 'YYYY-MM-DD')}`;
        default:
            return `>${format(startDateTime, 'YYYY-MM-DD')},<${format(
                endDateTime.setDate(endDateTime.getDate() + 1),
                'YYYY-MM-DD',
            )}`;
    }
}

/*
 * Takes an array of dates and returns an array of corresponding filters
 * e.g ['any', upcoming', 'thisyear']
 *
 * Algorythm breakdown:
 *   as long as there is one date in the array, result will contain 'any'
 *   if there is at least one date in the future, result will contain 'upcoming'
 *   else if there is at least on date...
 *     in the range of 365 days, result will contain 'thisyear'
 *     in the range of 30 days, result will contain 'thismonth'
 *     in the range of 7 days, result will contain 'thisweek'
 *
 * If a date is in the future AND in the current week, it should only return ['any', upcoming'],
 * and not ['any', upcoming', 'thisyear', 'thismonth', 'thisweek']
 *
 * @param {String[]} datesArray - list of dates (YYYY-MM-dd) sorted asc
 * @return {String[]}
 */
export function convertDatesToFilters(datesArray = []) {
    const result = [];

    const endDateTime = new Date();
    const startWeekDateTime = new Date(endDateTime.getTime() - dateMapping.thisweek * 86400000);
    const startMonthDateTime = new Date(endDateTime.getTime() - dateMapping.thismonth * 86400000);
    const startYearDateTime = new Date(endDateTime.getTime() - dateMapping.thisyear * 86400000);

    if (datesArray && datesArray.length > 0) {
        result.push('any');
        for (let i = 0; i < datesArray.length; i++) {
            if (!result.includes('upcoming') && isFuture(datesArray[i])) {
                result.push('upcoming');
            }
            if (
                !result.includes('thisyear') &&
                isWithinRange(datesArray[i], startYearDateTime, endDateTime)
            ) {
                result.push('thisyear');
            }
            if (
                !result.includes('thismonth') &&
                isWithinRange(datesArray[i], startMonthDateTime, endDateTime)
            ) {
                result.push('thismonth');
            }
            if (
                !result.includes('thisweek') &&
                isWithinRange(datesArray[i], startWeekDateTime, endDateTime)
            ) {
                result.push('thisweek');
            }
        }
    }

    return result;
}

export function secondsToIsoDuration(seconds) {
    if (seconds <= 0) return 'PT0S';

    const [Y, M, D, h, m, s] = [
        365 * 24 * 60 * 60,
        31 * 24 * 60 * 60,
        24 * 60 * 60,
        60 * 60,
        60,
        1,
    ].reduce(([acc, reminder], n) => [[...acc, Math.floor(reminder / n)], reminder % n], [
        [],
        seconds,
    ])[0];

    return (
        'P' +
        (Y ? Y + 'Y' : '') +
        (M ? M + 'M' : '') +
        (D ? D + 'D' : '') +
        (h || m || s ? 'T' : '') +
        (h ? h + 'H' : '') +
        (m ? m + 'M' : '') +
        (s ? s + 'S' : '')
    );
}

/**
 * Convert a Date to arguments
 *
 * @returns {[number]} arguments for the Date Constructor
 */
export const dateToArgs = date =>
    date
        ? [
              date.getFullYear(),
              date.getMonth(),
              date.getDate(),
              date.getHours(),
              date.getMinutes(),
              date.getSeconds(),
              date.getMilliseconds(),
          ]
        : [];
