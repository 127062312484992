import React, { useContext } from 'react';
import get from 'lodash/get';
import styled, { keyframes, css, withTheme } from 'styled-components';
import { assets, getMediaSrc } from '@one/theme-utils';

import usePrevious from '../util/usePrevious';

import Link from '../util/Link';
import { NavigationContext, TOGGLE_MOBILE_DRAWER } from '../context/NavigationContext';
import { getPathToRoute } from '../util/getPathToRoute';

const defaultStyles = {};

const openKeyframes = keyframes`
    from { transform: translateX(0); margin-right: 0; }
    to { transform: translateX(50%); margin-right: 100%; }
    `;
const closedKeyframes = keyframes`
    from { transform: translateX(50%); margin-right: 100%; }
    to { transform: translateX(0); margin-right: 0%; }
    `;

const getAnimation = ({ menuOpen, structureType }) => (menuOpen !== null && structureType !== 'desktop'
    ? css`
              animation: ${menuOpen ? openKeyframes : closedKeyframes} 0.3s forwards linear;
          `
    : css`
              animation: none;
          `);

const InitComponent = styled(({ menuOpen, structureType, ...rest }) => <Link {...rest} />)`
    position: relative;
    display: flex;
    align-items: center;
    min-width: 0;
    max-width: 100%;
    height: 100%;
    flex-shrink: 0;
    flex-basis: auto;
    transform: translateX(0);
    z-index: 2;
    padding-right: 16px;
    @media only screen and (min-width: 480px) {
        ${getAnimation}
    }
    img {
        max-height: 100%;
        max-width: 225px;
        width: 100%;
    }
`;

const Logo = ({
    styles = {},
    attributes,
    client: { hasWebPSupport = true } = {},
    structureType,
    label,
    ...props
}) => {
    const {
        path = '', // custom attribute path, '' if not set
        shouldOpenNewTab = false,
        shouldForceRefresh = false,
        popunder = { selected: false },
        isNoFollow = false,
        customPath = '',
        enableRedirect = true, // for compatibility with an old settings without new variable
    } = attributes;

    const {
        dispatch,
        navigationConfig,
        onFlirt4FreeLink,
        state: { menuOpen },
        routesMap,
    } = useContext(NavigationContext);

    const StyledComponent = styled(InitComponent)({
        ...defaultStyles,
        ...styles,
    });

    const isMenuOpen = usePrevious(menuOpen) === undefined ? null : menuOpen;
    const logoPath = get(navigationConfig, `${structureType}.path`, 'home');
    const altLabel = get(attributes, 'alt', '');

    let logoUrl = getPathToRoute({ routesMap, path: { type: 'HOME' } });
    if (path !== '') {
        logoUrl = getPathToRoute({ routesMap, path, customPath });
    } else if (logoPath !== 'home') {
        logoUrl = get(navigationConfig, `${structureType}.path`, { type: 'HOME' });
    }

    const handleLogoClick = urlForRefresh => () => {
        if (path === 'flirt4Free' && !!onFlirt4FreeLink) {
            onFlirt4FreeLink({ shouldOpenNewTab });
        } else {
            if (isMenuOpen) {
                dispatch({ type: TOGGLE_MOBILE_DRAWER });
            }
            if (shouldForceRefresh) {
                window.location.replace(urlForRefresh);
            }
        }
    };

    // forces default logo image
    let imgSrc;
    if (attributes && attributes.image === 'default') {
        imgSrc = assets('general.logo', props);
    } else {
        imgSrc = attributes.src;
    }

    return React.useMemo(
        () => (
            <>
                <StyledComponent
                    {...props}
                    to={logoUrl}
                    disabled={!enableRedirect}
                    onClick={handleLogoClick(logoUrl)}
                    menuOpen={isMenuOpen}
                    popunder={popunder}
                    {...(path !== '' && shouldOpenNewTab && { target: '_blank' })}
                    {...(path !== '' && isNoFollow && { rel: 'nofollow' })}
                >
                    <img
                        src={getMediaSrc(
                            imgSrc,
                            hasWebPSupport,
                        )}
                        alt={altLabel}
                    />
                </StyledComponent>
            </>
        ),
        [isMenuOpen, logoUrl, structureType, enableRedirect],
    );
};

export default withTheme(Logo);
