import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
    MobileBundles,
    DesktopBundles,
} from './BundelsElements';

const defaultStyles = {
    lineHeight: 1,
    display: 'flex',
    position: 'relative',
    textDecoration: 'none',
};

const Wrapper = styled.div(({ styles }) => styles);

const CampaignBundles = props => {
    const {
        isChildButton,
        structureType,
        ...rest
    } = props;

    return (
        <Wrapper
            {...rest}
            styles={{ ...defaultStyles, ...(isChildButton && { flexDirection: 'column' }) }}
        >
            {isChildButton && structureType === 'mobile' ? (
                <MobileBundles {...props} />
            ) : (
                <DesktopBundles {...props} />
            )}
        </Wrapper>
    );
};

CampaignBundles.propTypes = {
    id: PropTypes.number,
    content: PropTypes.object,
    isChildButton: PropTypes.bool,
    styles: PropTypes.object,
    structureType: PropTypes.string,
    attributes: PropTypes.object,
};

export default CampaignBundles;
