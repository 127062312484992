import React, { memo, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';
import isNil from 'lodash/isNil';

import { NavigationContext, TOGGLE_MOBILE_DRAWER } from '../context/NavigationContext';
import getStyles from '../util/getStyles';

const MENU_WIDTH = 300;

const defaultStyles = {
    color: 'white',
    display: 'flex',
    position: 'absolute',
    width: `${MENU_WIDTH}px`,
    top: '0',
    flexDirection: 'column',
    'z-index': '2',
};

const openKeyframes = keyframes`
    from { transform: translateX(0%); }
    to { transform: translateX(100%); }
    `;
const closedKeyframes = keyframes`
    from { transform: translateX(100%); }
    to { transform: translateX(0%); }
    `;

const Wrapper = styled.div`
    position: fixed;
    top: 100%;
    left: 50%;
    width: 100vw;
    height: calc(100vh - 100%);
    transform: translateX(-50%);
    z-index: 0;
    pointer-events: none !important;
`;

const Overlay = styled.div`
    top: 100%;
    left: 0;
    right: 0;
    position: fixed;
    width: 100vw;
    height: 100vh;
    transform: translateY(-100%);
    z-index: 1;
    transition: opacity 0.5s;

    ${({ styles: { color = 'rgba(0, 0, 0, 0.75)', blur = 0 }, menuOpen }) => `
        background: ${color};
        backdrop-filter: blur(${blur});
        opacity: ${(menuOpen && '1') || '0'};
        pointer-events: ${(menuOpen && 'auto') || 'none'};
    `}
`;

const Menu = styled.div`
    height: 100%;
    ${({ styles = {} }) => styles}

    &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 100%;
        display: block;
        width: 100%;
        background: inherit;
        height: 50%;
    }
    left: ${({ alignment }) => (alignment === 'left' ? `-${MENU_WIDTH + 1}px` : `calc(100% - ${MENU_WIDTH - 1}px)`)};
    transform: translateX(${({ alignment }) => (alignment === 'left' ? '0%' : '100%')});

    ${({ menuOpen, alignment }) => !isNil(menuOpen) && css`
        animation: ${(alignment === 'left' ? menuOpen : !menuOpen) ? openKeyframes : closedKeyframes} 0.5s forwards;
    `}

    pointer-events: all !important;
`;

const DrawerContainer = ({ styles: { alignment = 'left', ...elemStyles } = {}, children, ...rest }) => {
    const {
        state: { menuOpen },
        dispatch,
        onHelmet,
    } = useContext(NavigationContext);

    const { rootStyles = {}, childrenStyles = {} } = getStyles(elemStyles);
    const { overlay = {} } = childrenStyles;

    const handleOverlayClick = () => {
        if (menuOpen) {
            onHelmet({ previous: true, next: false });
            dispatch({ type: TOGGLE_MOBILE_DRAWER });
        }
    };

    return useMemo(
        () => (
            <Wrapper>
                <Menu
                    {...rest}
                    menuOpen={menuOpen}
                    alignment={alignment}
                    styles={{ ...defaultStyles, ...rootStyles }}
                >
                    {children}
                </Menu>
                <Overlay menuOpen={menuOpen} styles={overlay} onClick={handleOverlayClick} />
            </Wrapper>
        ),
        [menuOpen],
    );
};
DrawerContainer.propTypes = {
    children: PropTypes.node,
    styles: PropTypes.object,
};

export default memo(DrawerContainer);
