/* eslint-disable no-console */
import axios from 'axios';

export const getNotificationCount = ({ navigationApiUrl, memberUuid }) => {
    const url = `${navigationApiUrl}/v2/notifications`;

    return axios.get(url, { params: { memberUuid } })
        .then(res => res.data)
        .catch(err => {
            console.log(err);
            return {};
        });
};
