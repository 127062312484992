import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import { get } from 'lodash';

import createMediaRenderer from '../utils/createMediaRenderer';
import getSpanWidth from '../utils/getSpanWidth';

/**
 * A column component when building a grid
 *
 * Usage:
 * <Container>
 *     <Row>
 *         <Column
 *             span={{
 *                 'small': 12,
 *                 'medium': 6,
 *                 'large': 3
 *             }}
 *             offset={{
 *                 'extra-large': 2
 *             }}
 *             hide={{
 *                 'extra-large': 2
 *             }}
 *         >
 *             <... />
 *         </Column>
 *     </Row>
 * </Container>
 */
// TODO: Filter props automatically when styled-components supports it
// TODO: Remove blockName. Remove direct usage as a block in admin
// eslint-disable-next-line no-unused-vars
const Column = styled(
    ({
        span,
        hide,
        offset,
        theme,
        blockName,
        hasBackgroundColor,
        backgroundColor,
        fill,
        ...props
    }) => <div {...props} />,
)`
    position: relative;
    min-height: 1px;
    width: 100%;
    padding-left: ${props =>
        get(props, 'theme.gutter.pageGutter') || get(props, 'theme.grid.gutter', '10px')};

    ${({ span, hide, offset, theme, fill }) => {
        const renderer = createMediaRenderer(get(theme, 'grid.breakpoints'));

        return css`
            ${span &&
                renderer(span, spanBy => {
                    const width = getSpanWidth(spanBy, get(theme, 'grid.size', 4));
                    return `
                    flex: 0 0 ${width};
                    max-width: ${width};
                `;
                })}

            ${hide &&
                renderer(
                    hide,
                    hidden =>
                        hidden &&
                        `
                display: none !important;
            `,
                )}

            ${offset &&
                renderer(
                    offset,
                    offsetBy => `
                margin-left: ${getSpanWidth(offsetBy, get(theme, 'grid.size', 4))};
            `,
                )}

            ${fill &&
                `
                flex: 1 1 auto;
                width: auto;
            `}
        `;
    }}
`;
Column.propTypes = {
    /** An object that tells the column how wide it should be by breakpoint */
    span: PropTypes.object,

    /** An object that offsets the column by a span by breakpoint */
    offset: PropTypes.object,

    /** An object that hides the content by breakpoints */
    hide: PropTypes.object,
};
Column.displayName = 'Column';

export default Column;
