import { useMemo } from 'react';
import renderElements from './renderElements';

const NavigationContainer = ({ structure, structureType, isPreview }) => useMemo(
    () => renderElements({
        isPreview,
        structureType,
        structure: structure[structureType],
        styles: structure.styles,
    }),
    [structureType, structure],
);

export default NavigationContainer;
