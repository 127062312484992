import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { mediaUp } from '@one/theme-utils';

const defaultStyles = {
    lineHeight: 1,
    textDecoration: 'none',
    display: 'flex',
    position: 'relative',
    width: '100%',
    backgroundColor: '#000000',
    color: '#ffffff',
};

const StyledComponent = styled.div`
    padding: 0 16px 0 0;

    ${mediaUp('medium')`
        padding: 0;
    `}
    ${({ overrideStyles = {} }) => overrideStyles}
`;

const MobileHeader = ({ children, styles = {}, ...props }) => (
    <StyledComponent {...props} overrideStyles={{ ...defaultStyles, ...styles }}>
        {children}
    </StyledComponent>
);

MobileHeader.propTypes = {
    children: PropTypes.node,
    styles: PropTypes.object,
};

export default MobileHeader;
