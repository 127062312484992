import React, { useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import usePrevious from '../util/usePrevious';
import getStyles from '../util/getStyles';

import { NavigationContext, TOGGLE_MOBILE_DRAWER } from '../context/NavigationContext';

const defaultStyles = {
    hamburger: {
        position: 'relative',
        width: 48,
        height: 48,
        'z-index': '2',
    },
    span: {
        height: '3px',
        width: '20px',
    },
};

const InitComponent = styled.div`
        span {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            position: relative;
            display: block;

            background-color: currentColor;
            border-radius: 5px;

            &:before,
            &:after {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                background: currentColor;
                top: 50%;
                left: 50%;
                border-radius: 5px;
                transform-origin: center;
                transform: translate(-50%, -50%) rotate(45deg);
                transition: all 0.3s ease-in-out;
                border: inherit;
            }

            &:before {
                top: 0;
                transform: translate(-50%, -200%) rotate(0);
            }

            &:after {
                top: 0;
                transform: translate(-50%, 200%) rotate(0);
            }
        }
        &.active span:after {
            width: 20px;
            top: 50%;
            transform: translate(-50%, -50%) rotate(-45deg);
        }
        &.active span:before {
            width: 20px;
            top: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
        }
        &.active span {
            background-color: transparent;
        }
    `;

const Hamburger = ({ styles = {}, children, ...props }) => {
    const { rootStyles, childrenStyles } = getStyles(styles);
    const Span = styled.span({ ...defaultStyles.span, ...childrenStyles.span });

    const StyledComponent = styled(InitComponent)({ ...defaultStyles.hamburger, ...rootStyles });

    const hamburgerRef = useRef();

    const {
        state: { menuOpen },
        dispatch,
        onHelmet,
    } = useContext(NavigationContext);

    const handleHamburgerClick = () => {
        onHelmet({ previous: !!menuOpen, next: !menuOpen });
        dispatch({ type: TOGGLE_MOBILE_DRAWER });
    };

    const isMenuOpen = usePrevious(menuOpen) === undefined ? null : menuOpen;
    const notInitialAndClosed = isMenuOpen !== null && !isMenuOpen;

    useEffect(() => {
        if (hamburgerRef && hamburgerRef.current && hamburgerRef.current.classList) {
            if (menuOpen) {
                hamburgerRef.current.classList.add('active');
            } else {
                hamburgerRef.current.classList.remove('active');
            }
        }
    }, [isMenuOpen]);

    return React.useMemo(
        () => (
            <StyledComponent
                ref={hamburgerRef}
                {...props}
                onClick={handleHamburgerClick}
                className={notInitialAndClosed ? 'active' : ''}
            >
                <Span />
            </StyledComponent>
        ),
        [isMenuOpen],
    );
};

export default Hamburger;
