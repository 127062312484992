import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme, css } from 'styled-components';
import { Container as ContainerBase, createMediaRenderer } from '@one/grid';
import { mediaUp, mediaDown } from '@one/theme-utils';

const DefaultWidthWrapper = styled.div``;

const Container = styled(ContainerBase)`
    display: flex;
    flex-direction: column;
    ${mediaDown('small')`
        width:100%;
    `}
`;

const WidthOverrideContainer = styled(({ widthOverrides, theme, ...props }) => (
    <Container {...props} fluid />
))`
    ${({ theme: { grid: { breakpoints, containers } = {} } = {} }) => {
        const renderer = createMediaRenderer(breakpoints);
        return css`
            ${renderer(
        containers,
        width => `
                width: ${width};
            `,
    )}
        `;
    }};

    ${({ widthOverrides }) => mediaUp('small')`max-width: ${widthOverrides.small};`};

    ${({ widthOverrides }) => mediaUp('medium')`max-width: ${widthOverrides.medium};`};

    ${({ widthOverrides }) => mediaUp('large')`max-width: ${widthOverrides.large};`};

    ${({ widthOverrides }) => mediaUp('extra-large')`max-width: ${widthOverrides.extraLarge};`};

    ${({ widthOverrides }) => mediaUp('huge')`max-width: ${widthOverrides.huge};`};
`;

const WidthWrapper = ({
    isContentFullWidth = false,
    isSticky = false,
    widthOverrides = {},
    children,
}) => {
    if (isSticky && Object.values(widthOverrides).some(x => !!x)) {
        return (
            <WidthOverrideContainer widthOverrides={widthOverrides}>
                {children}
            </WidthOverrideContainer>
        );
    }

    if (isContentFullWidth) {
        return <DefaultWidthWrapper>{children}</DefaultWidthWrapper>;
    }

    return <Container>{children}</Container>;
};

WidthWrapper.propTypes = {
    isContentFullWidth: PropTypes.bool,
    isSticky: PropTypes.bool,
    widthOverrides: PropTypes.object,
    children: PropTypes.node,
};

export default withTheme(WidthWrapper);
