import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';

import SearchDrawer from '../components/SearchDrawer';
import DrawerSearchButton from '../components/DrawerSearchButton';
import { NavigationContext, TOGGLE_MOBILE_DRAWER } from '../../context/NavigationContext';

import SearchContent from './SearchContent';
import {
    MenuIcon,
    MenuIconButton,
} from './styledComponents';
import getCookie from '../../util/getCookie';
import { getSearchData } from '../../requests/loadSearchData';
import {
    compileStyledComponents,
    extractSearchWord,
    getFilteredResults,
    getSelectedTypes,
} from './searchBoxUtils';
import useClickOutside from '../../util/useClickOutside';

const MIN_LENGTH_SEARCH = 2;

const SearchBox = ({
    attributes: {
        fontSelection,
        inMobileDrawer,
        mobileButtonLabel,
        preIcon,
        postIcon,
        overrideTitles,
        searchDocumentTypes = 'all;actor;scene', // temporary fix: WEP1-54216,
        source,
    } = {},
    structureType,
    styles = {},
    styles: { drawerSearchButton } = {},
}) => {
    const isMobile = structureType === 'mobile';
    const searchTypes = getSelectedTypes(searchDocumentTypes);
    const { searchBoxStyles, StyledComponent } = compileStyledComponents(styles);

    const searchFieldRef = useRef();
    const searchFieldContainer = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [isSearchExpanded, setIsSearchExpanded] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [searchResult, setSearchResult] = useState({});
    const [selectedType, setSelectedType] = useState(get(searchTypes, '[0]', 'none'));

    const {
        state: { menuOpen },
        blockId,
        blockName,
        dispatch,
        onHelmet,
        onSearchRedirect,
        navigationApiUrl,
        pageType,
        referrerHeaders,
        user: { username, memberUuid } = {},
    } = useContext(NavigationContext);

    const onSearch = ({ type, value }) => {
        setIsLoading(true);
        getSearchData({
            limit: 10,
            offset: 1,
            query: value,
            type,
            blockId,
            blockName,
            pageType,
            navigationApiUrl,
            referrerHeaders,
            username: username || getCookie('__s'), // username or X-APP-SESSION-ID
            source,
            memberUuid,
        }).then(result => {
            setSearchResult(result);
        }).finally(() => {
            setIsLoading(false);
        });
    };

    const onSearchSlow = useCallback(throttle(onSearch, 1000, {
        leading: false,
        trailing: true,
    }), [pageType]);

    const handleOnchangeType = item => e => {
        e.preventDefault();
        e.stopPropagation();
        setSelectedType(item);
    };

    const handleOnKeydown = e => {
        const searchWord = extractSearchWord(e.target.value);

        if (e.key === 'Enter' && searchWord.length > MIN_LENGTH_SEARCH) {
            e.preventDefault();

            if (selectedType) {
                setIsSearchExpanded(false);
                if (menuOpen) {
                    dispatch({ type: TOGGLE_MOBILE_DRAWER });
                }
                if (onSearchRedirect) {
                    onSearchRedirect({
                        type: selectedType,
                        value: searchWord,
                        version: 'DARK_DISTRICT_V2',
                        source,
                    });
                }
            }
        }
    };

    const handleOnSearchChange = ({ target: { value = '' } = {} }) => {
        const searchWord = extractSearchWord(value);
        setSearchValue(value);
        if (searchWord.length > MIN_LENGTH_SEARCH && selectedType) {
            onSearchSlow({
                type: selectedType,
                value: searchWord,
            });
        }
    };

    const handleSearchResultClick = () => {
        setIsSearchExpanded(false);
        if (menuOpen) {
            dispatch({ type: TOGGLE_MOBILE_DRAWER });
        }
    };

    const handleSearchToggle = () => {
        setIsSearchExpanded(state => !state);
    };

    const handleClickOutside = () => {
        setIsSearchExpanded(false);
    };

    useClickOutside(searchFieldContainer, handleClickOutside);

    const filteredResults = getFilteredResults(searchResult, selectedType);

    const searchContentProps = {
        handleOnchangeType,
        handleOnKeydown,
        handleOnSearchChange,
        handleSearchResultClick,
        handleSearchToggle,
        inMobileDrawer,
        isMobile,
        isLoading,
        isSearchExpanded,
        overrideTitles,
        searchBoxStyles,
        searchFieldRef,
        searchValue,
        selectedType,
        searchTypes,
        searchResult: filteredResults,
        source,
    };

    useEffect(() => {
        onHelmet({ next: isSearchExpanded });
    }, [isSearchExpanded]);

    useEffect(() => {
        setSearchValue('');
        setSearchResult({});
    }, [pageType]);

    return (
        <>
            {inMobileDrawer ? (
                <>
                    <DrawerSearchButton
                        onClick={handleSearchToggle}
                        styles={drawerSearchButton}
                        attributes={{ label: mobileButtonLabel, preIcon, postIcon, fontSelection }}
                    />
                    <SearchDrawer>
                        <StyledComponent inMobileDrawer={inMobileDrawer}>
                            <SearchContent {...searchContentProps} />
                        </StyledComponent>
                    </SearchDrawer>
                </>
            ) : (
                <StyledComponent inMobileDrawer={inMobileDrawer} ref={searchFieldContainer}>
                    <SearchContent {...searchContentProps} />
                    <MenuIconButton
                        onClick={handleSearchToggle}
                        isSearchExpanded={isSearchExpanded}
                    >
                        <MenuIcon icon="Search" />
                    </MenuIconButton>
                </StyledComponent>
            )}
        </>
    );
};

SearchBox.propTypes = {
    attributes: PropTypes.object,
    structureType: PropTypes.string,
    styles: PropTypes.object,
};

export default SearchBox;
