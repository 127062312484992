import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import get from 'lodash/get';

import Link from '../util/Link';
import getStyles from '../util/getStyles';
import FeatherIcon from '../util/FeatherIcon';
import { getPathToRoute } from '../util/getPathToRoute';
import { NavigationContext } from '../context/NavigationContext';
import { getNotificationCount } from '../requests/loadNotifications';

// Tabs constants list from MA site.
// const ACCOUNT_INFO_SLUG = 'account-information';
// const ACCOUNT_BILLING_SLUG = 'billing-information';

const Wrapper = styled.div`
    position relative;

    .notification-icon {
        color: yellow;
    }

    .notification-dot {
        color: red;
        font-size: 1.5rem;
        top: -0.7rem;
        right: -0.2rem;
        position: absolute;
    }

    ${({ styles }) => styles}
`;

const NotificationBox = ({ styles = {} }) => {
    const {
        rootStyles = {},
        rootHoverStyles = {},
        childrenStyles = {},
    } = getStyles(styles);

    const componentStyles = {
        '.notification-dot': {
            ...childrenStyles.dot,
        },
        '.notification-icon': {
            ...childrenStyles.icon,
        },
        '&:hover': {
            '.notification-dot': {
                ...rootHoverStyles.dot,
            },
            '.notification-icon': {
                ...rootHoverStyles.icon,
            },
        },
        ...rootStyles,
    };

    const [notificationsCount, setNotificationsCount] = useState(0);
    const { navigationApiUrl, routesMap, paths: { notification } = {},
        user: { memberUuid } = {}, pageType } = useContext(NavigationContext);
    const previousPageTypeRef = useRef();

    useEffect(() => {
        let mounted = true;
        const onMounted = async () => {
            const { notifications = 0 } = await getNotificationCount({ navigationApiUrl, memberUuid });
            if (notifications > 0 && mounted) {
                setNotificationsCount(notifications);
            }
        };
        onMounted();

        return () => {
            mounted = false;
        };
    }, [memberUuid]);

    useEffect(() => {
        const previousPageType = previousPageTypeRef.current;
        if (notificationsCount > 0 && previousPageType === 'ACCOUNT') {
            const fetchNotificationsOnPageTypeChange = async () => {
                const { notifications = 0 } = await getNotificationCount({ navigationApiUrl, memberUuid });
                setNotificationsCount(notifications);
            };

            fetchNotificationsOnPageTypeChange();
        }

        previousPageTypeRef.current = pageType;
    }, [pageType]);

    const iconSize = get(childrenStyles, 'icon.size', '1.5rem');
    const notificationUrl = getPathToRoute({
        routesMap,
        path: notification,
    });

    return (
        <Wrapper styles={componentStyles}>
            <Link to={notificationUrl}>
                {notificationsCount > 0 && (
                    <span className="notification-dot">●</span>
                )}
                <FeatherIcon className="notification-icon" icon="Bell" size={iconSize} />
            </Link>
        </Wrapper>
    );
};

NotificationBox.propTypes = {
    styles: PropTypes.object,
};

export default NotificationBox;
