import styled from 'styled-components';
import getStyles from '../../util/getStyles';
import {
    InitComponent,
    MenuIconButton,
    MenuIconCloseButton,
    SearchField,
    SearchFieldOverlay,
    SearchResults,
    ResultContainer,
    ResultsHeader,
    ResultLink,
} from './styledComponents';

const defaultStyles = {
    margin: '8px 0px',
    fontSize: '12px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    color: 'black',
    borderRadius: '4px',
};

export const compileStyledComponents = styles => {
    const searchBoxStyles = {
        searchButton: { ...getStyles(styles.searchButton) },
        searchInput: { ...getStyles(styles.searchInput) },
        typeSelector: { ...getStyles(styles.typeSelector) },
        searchOverlay: { ...getStyles(styles.searchOverlay) },
        searchResult: { ...getStyles(styles.searchResult) },
        searchResultContainer: { ...getStyles(styles.searchResultContainer) },
        searchResultHeader: { ...getStyles(styles.searchResultHeader) },
        resultContainer: { ...getStyles(styles.resultContainer) },
        spinners: { ...getStyles(styles.spinners) },
    };

    const StyledComponent = styled(InitComponent)({
        ...defaultStyles,

        [MenuIconButton]: {
            ...searchBoxStyles.searchButton.rootStyles,
        },
        [MenuIconCloseButton]: {
            ...searchBoxStyles.searchButton.rootStyles,
        },
        [SearchField]: {
            ...searchBoxStyles.searchInput.rootStyles,
        },
        [SearchFieldOverlay]: {
            ...searchBoxStyles.searchOverlay.rootStyles,
        },
        [SearchResults]: {
            ...searchBoxStyles.searchResultContainer.rootStyles,
        },
        [ResultContainer]: {
            ...searchBoxStyles.searchResult.rootStyles,
        },
        [ResultsHeader]: {
            ...searchBoxStyles.searchResultHeader.rootStyles,
        },
        [ResultLink]: {
            ...searchBoxStyles.searchResult.rootStyles,
        },
    });

    return {
        searchBoxStyles,
        StyledComponent,
    };
};

export const extractSearchWord = value => String(value).trim();

export const getSelectedTypes = types => {
    if (Array.isArray(types)) {
        return types;
    }

    if (typeof types === 'string' && types.length > 0) {
        return types.split(';');
    }

    return [];
};

export const getFilteredResults = (searchResult, selectedType) => {
    if (selectedType === 'none') {
        return {
            actors: [],
            scenes: [],
            suggestions: [],
        };
    }

    const {
        actors = [],
        scenes = [],
        actorsCategories = [],
        scenesCategories = [],
        suggestions = [],
    } = searchResult;

    if (selectedType === 'all') {
        return {
            actors: actors.slice(0, 5),
            scenes: scenes.slice(0, 5),
        };
    }

    const limit = 8;
    if (selectedType === 'scene') {
        return {
            scenes: scenes.slice(0, limit),
        };
    }

    if (selectedType === 'actor') {
        return {
            actors: actors.slice(0, limit),
        };
    }

    if (selectedType === 'categories') {
        return {
            scenes: actorsCategories.slice(0, 5),
            actors: scenesCategories.slice(0, 5),
        };
    }

    if (selectedType === 'suggestions') {
        return {
            suggestions: suggestions.slice(0, limit),
        };
    }

    // eslint-disable-next-line no-console
    console.log('Search type is out of filter: ', selectedType);
    return searchResult;
};
