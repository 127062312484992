import React from 'react';
import PropTypes from 'prop-types';

import Button from '../Button';
import ButtonEntry from './ButtonEntry';

export const DesktopBundles = ({
    content: {
        collection = [],
        linkType,
    } = {},
    attributes,
    structureType,
    isChildButton,
    styles: {
        mainButton,
        subButton,
    } = {},
    ...rest
}) => (
    <Button
        attributes={attributes}
        structureType={structureType}
        styles={mainButton}
        isChildButton={isChildButton}
        {...rest}
    >
        {collection.map(item => (
            <ButtonEntry
                key={`dg_${item.id}`}
                id={item.id}
                title={item.title}
                linkType={item.linkType || linkType}
                linkTypePayload={{
                    bundleId: item.id,
                }}
                structureType={structureType}
                styles={subButton}
            />
        ))}
    </Button>
);

DesktopBundles.propTypes = {
    content: PropTypes.object,
    isChildButton: PropTypes.bool,
    styles: PropTypes.object,
    structureType: PropTypes.string,
    attributes: PropTypes.object,
};

export const MobileBundles = ({
    content: {
        collection = [],
        linkType,
    } = {},
    attributes,
    structureType,
    isChildButton,
    styles: {
        mainButton,
        subButton,
    } = {},
    ...rest
}) => (
    <>
        <Button
            attributes={attributes}
            structureType={structureType}
            styles={mainButton}
            isChildButton={isChildButton}
            {...rest}
        />
        {collection.map(item => (
            <ButtonEntry
                key={`dg_${item.id}`}
                id={item.id}
                title={item.title}
                linkType={item.linkType || linkType}
                linkTypePayload={{
                    bundleId: item.id,
                }}
                structureType={structureType}
                styles={subButton}
            />
        ))}
    </>
);

MobileBundles.propTypes = {
    content: PropTypes.object,
    isChildButton: PropTypes.bool,
    styles: PropTypes.object,
    structureType: PropTypes.string,
    attributes: PropTypes.object,
};
