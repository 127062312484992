import styled from 'styled-components';

const InitDropdown = styled.div`
    position: absolute;
    top: 100%;
    left: 0;
    max-width: 300px;
    min-width: 100%;
    width: max-content;
    padding: 8px 0;
`;

export const getButtonDropdown = styles => styled(InitDropdown)(styles);
