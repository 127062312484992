import React from 'react';
import PropTypes from 'prop-types';
import {
    AlertCircle,
    ArrowDown,
    ArrowDownLeft,
    ArrowDownRight,
    ArrowLeft,
    ArrowRight,
    ArrowUp,
    ArrowUpLeft,
    ArrowUpRight,
    Calendar,
    CheckCircle,
    ChevronRight,
    ChevronDown,
    Clock,
    CreditCard,
    ExternalLink,
    Eye,
    Heart,
    Home,
    List,
    Minus,
    Navigation,
    RotateCw,
    Plus,
    Shuffle,
    Unlock,
    Video,
    X,
    ThumbsUp,
    Search,
    Lock,
    Bell,
} from 'react-feather';

const icons = {
    AlertCircle,
    ArrowDown,
    ArrowDownLeft,
    ArrowDownRight,
    ArrowLeft,
    ArrowRight,
    ArrowUp,
    ArrowUpLeft,
    ArrowUpRight,
    Calendar,
    CheckCircle,
    ChevronRight,
    ChevronDown,
    Clock,
    CreditCard,
    ExternalLink,
    Eye,
    Heart,
    Home,
    List,
    Minus,
    Navigation,
    RotateCw,
    Plus,
    Shuffle,
    Unlock,
    Video,
    X,
    ThumbsUp,
    Search,
    Lock,
    Bell,
};

const FeatherIcon = ({ icon, ...props }) => {
    if (icon in icons) {
        const Icon = icons[icon];
        return <Icon {...props} />;
    }
    return null;
};
FeatherIcon.propTypes = {
    icon: PropTypes.string,
};

export default FeatherIcon;
