import { useCallback, useEffect, useState } from 'react';
import throttle from 'lodash/throttle';

const useWindowSize = (options = {}) => {
    const { wait = 100 } = options;
    const isClient = typeof window === 'object';
    const hasDocument = typeof document !== 'undefined' && document.body;
    const [size, setSize] = useState({
        width: isClient ? window.innerWidth : 0,
        height: isClient ? window.innerHeight : 0,
        clientWidth: (hasDocument && document.body.clientWidth) || 0,
        clientHeight: (hasDocument && document.body.clientHeight) || 0,
    });

    const onResize = useCallback(() => setSize({
        width: window.innerWidth,
        height: window.innerHeight,
        clientWidth: (hasDocument && document.body.clientWidth) || 0,
        clientHeight: (hasDocument && document.body.clientHeight) || 0,
    }), []);

    const onResizeSlow = useCallback(throttle(onResize, wait, {
        leading: false,
        trailing: true,
    }), []);

    useEffect(() => {
        window.addEventListener('resize', onResizeSlow);
        return () => window.removeEventListener('resize', onResizeSlow);
    }, []);

    return size;
};

export default useWindowSize;
