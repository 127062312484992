import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { get } from 'lodash';
import createMediaRenderer from '../utils/createMediaRenderer';
import getSpanWidth from '../utils/getSpanWidth';

// TODO: Filter props automatically when styled-components supports it
// eslint-disable-next-line no-unused-vars
const Item = styled(({ span, theme, ...props }) => <div {...props} />)`
    position: relative;
    min-height: 1px;
    width: 100%;

    padding-right: ${props => get(props, 'theme.grid.gutter', '10px')};
    margin-bottom: ${props => get(props, 'theme.grid.gutter', '10px')};

    ${({ span, theme }) => {
        const renderer = createMediaRenderer(get(theme, 'grid.breakpoints'));
        return css`
            ${span &&
                renderer(span, spanBy => {
                    const width = getSpanWidth(spanBy, get(theme, 'grid.size', 4));
                    return `
                    flex: 0 0 ${width};
                    max-width: ${width};
                `;
                })}
        `;
    }}
`;
Item.displayName = 'Item';

class GridItem extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        size: PropTypes.object,
        theme: PropTypes.object,
        children: PropTypes.node,
    };

    render() {
        const { className, theme, size, children } = this.props;

        // TODO: Review this performance killer
        // const trimmedChildren = Children.toArray(children).slice(0, limit);

        return (
            <Item span={size} theme={theme} className={className}>
                {children}
            </Item>
        );
    }
}
GridItem.displayName = 'GridItem';

export default GridItem;
