import { mediaUp, mediaDown, mediaOnly } from './media';

function getMediaType(breakpoint) {
    if (/-up$/.test(breakpoint)) {
        return mediaUp;
    }

    if (/-down$/.test(breakpoint)) {
        return mediaDown;
    }

    return mediaOnly;
}

/**
 * Creates a function that will iterate over a breakpoint configuration
 * returning renderered templates wrapped in a media query
 *
 * Map keys may look like:
 *     [breakpoint-name],
 *     [breakpoint-name]-up,
 *     [breakpoint-name]-down
 *
 * @param  {Object} breakpoints The breakpoint configuration map
 * @return {function}           The media query renderer
 */
export default function createMediaRenderer(breakpoints) {
    return (config, template) => {
        const entries = Object.entries(config);

        return entries.map(([name, value]) => {
            const mediaType = getMediaType(name);
            const breakpointName = name.replace(/-(up|down)$/, '');

            return mediaType(breakpointName, breakpoints)`
                ${template(value)}
            `;
        });
    };
}
