import invariant from 'invariant';
import { css } from 'styled-components';

function assertSorted(breakpoints) {
    const values = Object.values(breakpoints);

    return values.every((value, index) => index === 0 || values[index - 1] <= value);
}

/**
 * Returns the name of the next breakpoint
 *
 * @param  {String}      name        Breakpoint name
 * @param  {Object}      breakpoints Breakpoint map
 * @return {String|null}             Next breakpoint name
 */
export function getNextBreakpoint(name, breakpoints = {}) {
    invariant(
        assertSorted(breakpoints),
        'getNextBreakpoint(...): Breakpoints should be defined in ascending order.',
    );

    const names = Object.keys(breakpoints);
    const index = names.indexOf(name);

    // Checks if the breakpoint is unknown or the last
    if (index < 0 || index >= names.length - 1) {
        return null;
    }

    return names[index + 1];
}

/**
 * Returns the minimum width for a breakpoint
 *
 * @param  {String} name        Breakpoint name
 * @param  {Object} breakpoints Breakpoints map
 * @return {Number}             Minimum width for this breakpoint
 */
export function getBreakpointMin(name, breakpoints = {}) {
    return breakpoints[name] || 0;
}

/**
 * Returns the maximum width for a breakpoint
 *
 * @param  {String} name        Breakpoint name
 * @param  {Object} breakpoints Breakpoints map
 * @return {Number}             Maximum width for this breakpoint
 */
export function getBreakpointMax(name, breakpoints = {}) {
    const next = getNextBreakpoint(name, breakpoints);
    return next ? breakpoints[next] - 1 : Infinity;
}

/**
 * Returns an identity template
 * @return {function} Tagged template literal
 */
export function breakpointAlways() {
    return (...args) => css`
        ${css(...args)}
    `;
}

/**
 * Returns a range media query template
 * @param  {Number}   min Min width
 * @param  {Number}   max Max width
 * @return {function}     Tagged template literal
 */
export function breakpointBetween(min, max) {
    return (...args) => css`
        @media (min-width: ${min}px) and (max-width: ${max}px) {
            ${css(...args)}
        }
    `;
}

/**
 * Returns a range media query template to exclude
 * @param  {Number}   min Min width
 * @param  {Number}   max Max width
 * @return {function}     Tagged template literal
 */
export function breakpointExclude(min, max) {
    return (...args) => css`
        @media (max-width: ${min}px), (min-width: ${max}px) {
            ${css(...args)}
        }
    `;
}

/**
 * Returns a min-width media query template
 * @param  {Number}   min Min width
 * @return {function}     Tagged template literal
 */
export function breakpointUp(min) {
    return (...args) => css`
        @media (min-width: ${min}px) {
            ${css(...args)}
        }
    `;
}

/**
 * Returns a max-width media query template
 *
 * @param  {Number}   max Max width
 * @return {function}     Tagged template literal
 */
export function breakpointDown(max) {
    return (...args) => css`
        @media (max-width: ${max}px) {
            ${css(...args)}
        }
    `;
}
