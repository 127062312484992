import PropTypes from 'prop-types';
import styled from 'styled-components';
import { get } from 'lodash';
/**
 * A row component when building a grid
 *
 * Usage:
 * <Container>
 *     <Row reversed? spaced?>
 *         <Column>
 *             <... />
 *         </Column>
 *     </Row>
 * </Container>
 */

const getVerticalSpacing = props => {
    const { verticalPadding, topMargin, bottomMargin } = props;

    const defaultGutter =
        get(props, 'theme.gutter.pageGutter') || get(props, 'theme.grid.gutter', '10px');
    let result = `margin: ${defaultGutter} 0px;`;
    if (verticalPadding && !topMargin && !bottomMargin) {
        result = `margin: ${verticalPadding} 0px;`;
    } else if (topMargin && bottomMargin) {
        // top and bottom are BOTH required, otherwise we default to gutter values
        result = `
            margin-top: ${topMargin};
            margin-bottom: ${bottomMargin};
        `;
    }
    return result;
};

const Row = styled.div`
    box-sizing: border-box;
    display: flex;
    flex: 0 1 100%;
    flex-wrap: wrap;

    ${props =>
        props.aligned &&
        `
        padding-left: ${
            props.leftPadding
                ? props.leftPadding
                : get(props, 'theme.gutter.pageGutter') || get(props, 'theme.grid.gutter', '10px')
        };
        padding-right: ${
            props.rightPadding
                ? props.rightPadding
                : get(props, 'theme.gutter.pageGutter') || get(props, 'theme.grid.gutter', '10px')
        };
    `}

    ${props => props.spaced && getVerticalSpacing(props)}

    margin-left: -${props =>
        get(props, 'theme.gutter.pageGutter') || get(props, 'theme.grid.gutter', '10px')};

    ${props =>
        props.reversed &&
        `
        flex-direction: row-reverse;
    `}

    ${({ horizontalAlignment = 'initial' }) => `
        justify-content: ${horizontalAlignment};
    `}
`;
Row.displayName = 'Row';

Row.propTypes = {
    /** Space horizontally by 1 gutter size */
    aligned: PropTypes.bool,

    /** Horizontal Padding Override */
    leftPadding: PropTypes.string,
    rightPadding: PropTypes.string,

    /** Reverse the column order within */
    reversed: PropTypes.bool,

    /** Space vertically by 1 gutter size */
    spaced: PropTypes.bool,

    /** Vertical Padding Override */
    verticalPadding: PropTypes.string,
};

export default Row;
