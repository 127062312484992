import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { objectToQueryString } from './routeUtils';
import { NavigationContext } from '../context/NavigationContext';
import { getPathToRoute } from './getPathToRoute';

const StyledLink = styled.a`
    pointer-events: cursor;
    user-select: none;
`;

const StyledSpan = styled.span`
    cursor: default;
    pointer-events: none;
    user-select: none;
`;

const Link = ({
    onClick: onClickProp,
    popunder = {},
    target,
    to = '',
    disabled = false,
    ...rest
}) => {
    const { onLink, query, routesMap } = useContext(NavigationContext);
    const queryString = objectToQueryString(query);
    const href = to && (typeof to === 'string' ? to : getPathToRoute({ routesMap, path: to }));
    const isTargetBlank = target === '_blank';

    const onClick = event => {
        if (!isTargetBlank) {
            event.preventDefault();
        }
        if (onClickProp) {
            onClickProp(event);
        }
        if (!isTargetBlank && to && !disabled) {
            const { className } = rest;

            onLink({
                className,
                event,
                href,
                query,
                queryString,
                popunder,
                target,
                to,
                disabled,
            });
        }
    };

    return to && !disabled
        ? <StyledLink href={href} onClick={onClick} target={target} {...rest} />
        : <StyledSpan onClick={onClick} {...rest} />;
};

Link.propTypes = {
    onClick: PropTypes.func,
    popunder: PropTypes.object,
    target: PropTypes.oneOf([null, '_blank', '_parent', '_self', '_top']),
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    disabled: PropTypes.bool,
};

export default Link;
