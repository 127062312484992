import React from 'react';
import { Play, Star, Monitor, Film, Image } from 'react-feather';
import styled, { css } from 'styled-components';
import { Container as ContainerBase } from '@one/grid';
import { mediaDown } from '@one/theme-utils';
import FeatherIcon from '../../util/FeatherIcon';
import Link from '../../util/Link';
import Spinner from '../../util/Spinner';

export const Container = styled(ContainerBase)`
    margin: 0;
    display: flex;
`;

export const InitComponent = styled.div`
    ${props => (!props.inMobileDrawer
        ? `
            display: flex;
            min-width: 1%;
            position: relative;
            justify-content: center;
            align-items: center;

            &:not(:last-child) {
                margin-right: ${props.theme.grid.gutter};
            }
            `
        : '')}

    input::-ms-clear {
        display: none;
        width: 0;
        height: 0;
    }
`;

export const MenuIcon = styled(FeatherIcon)`
    width: 20px;
    height: 20px;
`;

export const MenuIconButton = styled.button`
    border-style: solid;
    border-color: transparent;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    min-height: 32px;
    min-width: 32px;
    border-radius: 10px;
    color: #888888;
    opacity: ${props => (props.isSearchExpanded ? '0' : '1')};
`;

export const MenuIconCloseButton = styled(MenuIconButton)``;

export const ResultContainer = styled.span`
    display: flex;
    padding: 5px 10px;
    &:first-child {
        margin-top: 10px;
    }
    &:last-child {
        margin-bottom: 10px;
    }
`;

export const ResultLink = styled(Link)`
    color: #000;
    flex: 1;
    overflow: hidden;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover {
        text-decoration: underline;
    }
`;

export const SearchField = styled.input`
    border-style: solid;
    border-color: transparent;
    height: 100%;
    min-width: 0;
    width: ${props => (props.isExpanded ? 'auto' : '0')};
    padding: ${props => (props.isExpanded ? '0 8px' : '0')};
    transition: width 0.2s ease-out;
    flex: 2;
    padding-right: 32px;
`;

export const SearchFieldContainer = styled.div`
    min-width: 0;
    margin: 0 4px 0 0;
    display: flex;
    flex: 1;
    position: relative;
    & > * {
        margin-left: 0;
        margin-right: 0;
    }
`;

export const SearchFieldOverlay = styled.div`
    ${props => {
        const topPosition = 0;
        const width = props.isMobile ? '100%' : '400px';
        const position = props.isMobile ? 'fixed' : 'absolute';
        return css`
            width: ${props.isExpanded ? width : '0'};
            display: ${props.isExpanded ? 'flex' : 'none'};
            position: ${position};
            padding: ${props.isMobile ? '8px 0' : '8px'};
            margin: ${props.isMobile ? '0' : '0 -8px 0 0'};
            border-radius: ${props.isMobile ? '0' : '32px'};
            top: ${props.inMobileDrawer ? `${topPosition}px` : 'unset'};

            ${props.inMobileDrawer
        ? `
            left:0;
                &:before{
                    content:'';
                    display:block;
                    background-color: ${props.styles.rootStyles.backgroundColor};
                    backdrop-filter: blur(${props.styles.rootStyles.blur});
                    position:absolute;
                    top:0;
                    left:0;
                    height:100vh;
                    width:100%;
                    z-index:-1;
                }
            `
        : `
                right:0;
                background-color: ${props.styles.rootStyles.backgroundColor};
                backdrop-filter: blur(${props.styles.rootStyles.blur});
                `};
        `;
    }};
    transition: width 0.4s ease-out;
    height: 48px;
    overflow: ${props => (props.isExpanded ? 'visible' : 'hidden')};
    -webkit-backface-visibility: hidden;
    justify-content: center;
    z-index: 2000;

    ${mediaDown('small')`
        & > div {
            width:100%;
            padding: 0 ${props => props.theme.grid.gutter};
        `}
`;

export const SearchIcon = styled(FeatherIcon)`
    width: 32px;
    height: 32px;
    padding: 5px;
    color: #888888;
`;

export const searchMenuIcons = {
    scene: {
        icon: <Play />,
        label: 'Scenes',
    },
    actor: {
        icon: <Star />,
        label: 'Actors',
    },
    serie: {
        icon: <Monitor />,
        label: 'Series',
    },
    movie: {
        icon: <Film />,
        label: 'Movies',
    },
    gallery: {
        icon: <Image />,
        label: 'Gallery',
    },
};

export const SelectedType = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    min-width: 44px;
    text-transform: uppercase;
    font-size: 14px;
    flex: 1;
    padding: 0 8px;
    border-width: 0;
    border-style: solid;
    border-color: transparent;

    & span {
        padding: 0 8px;
    }

    > svg {
        width: 17px;
        height: 17px;

        ${mediaDown('small')`
            &:first-child {
                display:none;
            }
        `}
    }
`;

export const SmallSpinner = styled(Spinner)`
    position: absolute;
    right: 0;
    & > div {
        font-size: 3px;
        margin: 7px;
    }
`;
SmallSpinner.displayName = 'SmallSpinner';

export const TypeButton = styled.button`
    display: flex;
    padding: 8px;
    cursor: pointer;
    width: 100%;
    background-color: inherit;
    color: inherit;

    &:hover {
        background: ${props => props.typeBgColor};
        color: ${props => props.textColor};
    }

    &:first-child {
        border: 0;
    }

    & span {
        padding: 0 8px;
    }

    > svg {
        width: 16px;
        height: 16px;
    }
`;

export const TypeResults = styled.div`
    position: absolute;
    right: 0;
    left: 0;
    top: 100%;
    border-top: 0;
    z-index: 1;
    line-height: 1.2;
    color: white;
    background-color: '#888888';
`;

export const SearchResults = styled.div`
    position: absolute;
    right: 0;
    top: 100%;
    width: 100%;
    background: #fff;
    border-radius: 0 0 5px 5px;
    z-index: 911;
    line-height: 1.2;
`;
