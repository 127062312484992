import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';

const defaultsMap = { borderColor: 'transparent' };

const getDefaultValues = (property, value) => (!value
    && defaultsMap[property] ? defaultsMap[property] : value);

export const stylesActionsReducer = (accumulator, [property, value]) => {
    const { normal = {}, hover = {} } = accumulator;
    const [defaultKey, stateKey] = property.split(':');
    switch (defaultKey) {
        case 'hover':
            return { normal, hover: { ...hover, [stateKey]: getDefaultValues(stateKey, value) } };
        default:
            return {
                hover,
                normal: { ...normal, [defaultKey]: getDefaultValues(defaultKey, value) },
            };
    }
};

const getStyles = styles => {
    const nested = {};

    const rootStylesRaw = pickBy(styles, (value, key) => {
        const isNested = key.includes('/');
        if (isNested) {
            nested[key] = value;
        }
        return !isNested;
    });
    const { normal = {}, hover = {} } = Object.entries(rootStylesRaw).reduce(
        stylesActionsReducer,
        {},
    );

    const rootStyles = { ...normal, ...(!isEmpty(hover) && { '&:hover': hover }) };

    let rootHoverStyles = {};

    const childrenStylesRaw = Object.entries(nested).reduce((acc, [key, style]) => {
        const [element, attr] = key.split('/');
        return { ...acc, [element]: { ...acc[element], [attr]: style } };
    }, {});

    const childrenStyles = Object.entries(childrenStylesRaw).reduce(
        (acc, [deepChildName, deepChildValue]) => {
            const { normal: childNormal = {}, hover: childHover = {} } = Object.entries(
                deepChildValue,
            ).reduce(stylesActionsReducer, {});

            rootHoverStyles = {
                ...rootHoverStyles,
                ...(!isEmpty(childHover) && { [deepChildName]: { ...rootHoverStyles[deepChildName], ...childHover } }),
            };
            return {
                ...acc,
                [deepChildName]: {
                    ...acc[deepChildName],
                    ...childNormal,
                },
            };
        },
        {},
    );

    return { rootStyles, childrenStyles, rootHoverStyles };
};

export default getStyles;
