/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import { ChevronUp, ChevronDown } from 'react-feather';
import { NavigationContext } from '../../context/NavigationContext';
import {
    Container,
    MenuIcon,
    MenuIconCloseButton,
    SearchField,
    SearchFieldContainer,
    SearchFieldOverlay,
    SearchIcon,
    searchMenuIcons,
    SelectedType,
    SmallSpinner,
    TypeButton,
    TypeResults,
} from './styledComponents';
import SearchResult from './SearchResult';

const SearchContent = ({
    handleOnchangeType,
    handleOnKeydown,
    handleOnSearchChange,
    handleSearchResultClick,
    handleSearchToggle,
    handleSearchTypesToggle,
    inMobileDrawer,
    isMobile,
    isSearchExpanded,
    isTypesExpanded,
    searchBoxStyles,
    searchFieldRef,
    searchValue,
    selectedType,
    searchTypes,
}) => {
    const { result: { isLoading = false } = {} } = useContext(NavigationContext);
    return (
        <SearchFieldOverlay
            isExpanded={isSearchExpanded}
            ref={searchFieldRef}
            isMobile={isMobile}
            inMobileDrawer={inMobileDrawer}
            styles={searchBoxStyles.searchOverlay}
        >
            {isSearchExpanded && (
                <Container>
                    {isSearchExpanded && <SearchIcon icon="Search" />}
                    <SearchFieldContainer>
                        {selectedType && searchTypes && searchTypes.length > 1 && (
                            <SelectedType onClick={handleSearchTypesToggle}>
                                {searchMenuIcons[selectedType].icon}
                                <span>{searchMenuIcons[selectedType].label}</span>
                                {isTypesExpanded ? <ChevronUp /> : <ChevronDown />}
                            </SelectedType>
                        )}
                        <SearchField
                            isExpanded={isSearchExpanded}
                            onChange={handleOnSearchChange}
                            onKeyPress={handleOnKeydown}
                            value={searchValue}
                            autoFocus
                        />
                        {isLoading && <SmallSpinner />}
                        {isSearchExpanded && isTypesExpanded && (
                            <TypeResults isMobile={isMobile}>
                                {searchTypes && searchTypes
                                    .filter(type => type !== selectedType)
                                    .map(type => (
                                        <TypeButton
                                            key={type}
                                            isActive={type === selectedType}
                                            onClick={handleOnchangeType}
                                            value={type}
                                        >
                                            {searchMenuIcons[type].icon}
                                            <span>{searchMenuIcons[type].label}</span>
                                        </TypeButton>
                                    ))}
                            </TypeResults>
                        )}
                        <SearchResult
                            handleSearchResultClick={handleSearchResultClick}
                            isMobile={isMobile}
                            isShow={isSearchExpanded && searchValue.length > 2}
                        />
                    </SearchFieldContainer>
                    <MenuIconCloseButton onClick={handleSearchToggle}>
                        {isSearchExpanded && <MenuIcon icon="X" />}
                    </MenuIconCloseButton>
                </Container>
            )}
        </SearchFieldOverlay>
    );
};

export default SearchContent;
